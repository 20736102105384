import { useEffect, useState } from "react";
import apiRequest from "../api/apiRequest";

const useWarehouseProduct = (query) => {
  const [warehouseProducts, setWarehouseProducts] = useState();
  const [warehouseLoading, setWarehouseLoading] = useState(true);
  const [warehouseError, setWarehouseError] = useState(null);

  const getWarhouseProduct = async () => {
    try {
      const response = await apiRequest.get(`/warehouse/get?${query}`);
      if (response.status_code === 200) {
        setWarehouseProducts(response.result);
      } else {
        setWarehouseError("Failed to fetch data");
      }
    } catch (error) {
      setWarehouseError("Failed to fetch data");
    } finally {
      setWarehouseLoading(false);
    }
  };

  useEffect(() => {
    getWarhouseProduct();
  }, []);

  return {
    warehouseProducts,
    warehouseLoading,
    warehouseError,
    warehouseRefetch: getWarhouseProduct,
  }; // Return a promise if needed
};

export default useWarehouseProduct;
