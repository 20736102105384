import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";
import useFarmer from "../../hooks/farmer/useFarmer";
import FormSelect from "../../components/form/FormSelect";
import FormInputHolder from "../../components/form/FormInputHolder";
import LoadingData from "../../components/data/LoadingData";
import FormLink from "../../components/form/FormLink";
import toFarmerSelect from "../../functions/toFarmerSelect";
import useUser from "../../hooks/useUser";

const FarmerEdit = () => {
  const { userRole } = useUser();
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const { farmers, farmersLoading } = useFarmer();
  const farmerSelect = toFarmerSelect(farmers);

  const [farmerID, setFarmerID] = useState();
  const [farmerActive, setFarmerActive] = useState();
  const [farmerFirstName, setFarmerFirstName] = useState();
  const [farmerLastName, setFarmerLastName] = useState();
  const [farmerCity, setFarmerCity] = useState();
  const [farmerStreet, setFarmerStreet] = useState();
  const [farmerStreetNumber, setFarmerStreetNumber] = useState();
  const [farmerPhoneNumber, setFarmerPhoneNumber] = useState();
  const [farmerUIN, setFarmerUIN] = useState();
  const [farmerFarmID, setFarmerFarmID] = useState();
  const [farmerFarmCows, setFarmerFarmCows] = useState();
  const [farmerBankNumber, setFarmerBankNumber] = useState();

  const handleFarmerChange = (farmer) => {
    setFarmerID(farmer._id);
    setFarmerActive(farmer.active);
    setFarmerFirstName(farmer.first_name);
    setFarmerLastName(farmer.last_name);
    setFarmerCity(
      farmer.contact != null && farmer.contact.address != null
        ? farmer.contact.address.city
        : ""
    );
    setFarmerStreet(
      farmer.contact != null && farmer.contact.address != null
        ? farmer.contact.address.street
        : ""
    );
    setFarmerStreetNumber(
      farmer.contact != null && farmer.contact.address != null
        ? farmer.contact.address.street_number
        : ""
    );
    setFarmerPhoneNumber(
      farmer.contact != null ? farmer.contact.phone_number : ""
    );
    setFarmerUIN(farmer.contact != null ? farmer.contact.uin : "");
    setFarmerFarmID(farmer.farm != null ? farmer.farm.id : "");
    setFarmerFarmCows(farmer.farm != null ? farmer.farm.cows : "");
    setFarmerBankNumber(farmer.bank != null ? farmer.bank.checking_number : "");
  };

  useEffect(() => {
    if (farmers && farmers.length > 0) {
      const initialFarmer = farmers[0];
      handleFarmerChange(initialFarmer);
    }
  }, [farmers]);

  useEffect(() => {
    if (farmers && farmers.length > 0) {
      const selectedFarmer = farmers.find((farmer) => farmer._id === farmerID);
      handleFarmerChange(selectedFarmer);
    }
  }, [farmerID]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      id: farmerID,
      uin: farmerUIN,
      first_name: farmerFirstName,
      last_name: farmerLastName,
      contact: {
        address: {
          city: farmerCity,
          street: farmerStreet,
          street_number: farmerStreetNumber,
        },
        phone_number: farmerPhoneNumber,
      },
      farm: {
        id: farmerFarmID,
        cows: farmerFarmCows,
      },
      bank: {
        checking_number: farmerBankNumber,
      },
      active: farmerActive,
    };
    const response = await apiRequest.post("/farmer/edit", request);
    setLoadingData(false);
    if (response.status_code === 200) {
    } else {
      setFormError(response.error);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Uredi Farmera</SectionHeading>
      </SectionHead>
      <SectionContent>
        {farmersLoading ? (
          <LoadingData />
        ) : (
          <Form>
            {farmers && farmers.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Farmer"}
                  options={farmerSelect}
                  onChange={(e) => {
                    handleFormChange();
                    setFarmerID(e.target.value);
                  }}
                />
                <FormInputHolder>
                  <FormSelect
                    placeholder={"Status"}
                    value={farmerActive}
                    options={
                      farmerActive
                        ? [
                            { placeholder: "Aktivan", value: true },
                            { placeholder: "Neaktivan", value: false },
                          ]
                        : [
                            { placeholder: "Neaktivan", value: false },
                            { placeholder: "Aktivan", value: true },
                          ]
                    }
                    onChange={(e) => {
                      handleFormChange();
                      setFarmerActive(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"user"}
                    placeholder={"Ime"}
                    value={farmerFirstName}
                    onChange={(e) => {
                      handleFormChange();
                      setFarmerFirstName(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"user"}
                    placeholder={"Prezime"}
                    value={farmerLastName}
                    onChange={(e) => {
                      handleFormChange();
                      setFarmerLastName(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"city"}
                    placeholder={"Grad"}
                    value={farmerCity}
                    onChange={(e) => {
                      handleFormChange();
                      setFarmerCity(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"road"}
                    placeholder={"Ulica"}
                    value={farmerStreet}
                    onChange={(e) => {
                      handleFormChange();
                      setFarmerStreet(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"hashtag"}
                    placeholder={"Broj ulice"}
                    value={farmerStreetNumber}
                    onChange={(e) => {
                      handleFormChange();
                      setFarmerStreetNumber(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"phone"}
                    placeholder={"Broj Telefona"}
                    value={farmerPhoneNumber}
                    onChange={(e) => {
                      handleFormChange();
                      setFarmerPhoneNumber(e.target.value);
                    }}
                  />
                  {userRole === "admin" && (
                    <>
                      <FormInput
                        icon={"id-card"}
                        placeholder={"JMBG"}
                        value={farmerUIN}
                        onChange={(e) => {
                          handleFormChange();
                          setFarmerUIN(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"farm"}
                        placeholder={"Broj Gazdinstva"}
                        value={farmerFarmID}
                        onChange={(e) => {
                          handleFormChange();
                          setFarmerFarmID(e.target.value);
                        }}
                      />
                      <FormInput
                        type="number"
                        icon={"cow"}
                        placeholder={"Broj Krava"}
                        value={farmerFarmCows}
                        onChange={(e) => {
                          handleFormChange();
                          setFarmerFarmCows(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"building-columns"}
                        placeholder={"Broj Tekućeg Računa"}
                        value={farmerBankNumber}
                        onChange={(e) => {
                          handleFormChange();
                          setFarmerBankNumber(e.target.value);
                        }}
                      />
                    </>
                  )}
                </FormInputHolder>

                {formError && <FormError>{formError}</FormError>}
                <FormSubmit
                  onClick={() => handleFormSubmit()}
                  isLoading={formLoading}
                >
                  Uredi
                </FormSubmit>
              </>
            ) : (
              <>
                <FormError>Nema Farmera za uređivanje</FormError>
                <FormLink to={"/farmer/add"}>Dodaj Farmera</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default FarmerEdit;
