import React from "react";
import useUser from "../hooks/useUser";
import MilkView from "./milk/MilkView";
import MilkAdd from "./milk/MilkAdd";

const Home = () => {
  const { userRole } = useUser();
  return (
    <>
      {userRole === "admin" && (
        <>
          <MilkView />
        </>
      )}
      {userRole === "driver" && (
        <>
          <MilkAdd />
          <MilkView />
        </>
      )}
    </>
  );
};

export default Home;
