const toMilkSelect = (milks) => {
  const milkSelect =
    milks &&
    milks.map((milk) => {
      return {
        placeholder:
          milk.farmer.first_name +
          " " +
          milk.farmer.last_name +
          " - " +
          milk.amount +
          "L",
        value: milk._id,
      };
    });
  return milkSelect;
};

export default toMilkSelect;
