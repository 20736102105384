import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import toSizePDF from "../../../functions/toSizePDF";

import logo from "../../../assets/media/logo.png";
import PoppinsRegular from "../../../assets/fonts/PoppinsRegular-400.ttf";
import PoppinsBold from "../../../assets/fonts/PoppinsBold-700.ttf";

Font.register({
  family: "Poppins",
  src: PoppinsRegular,
});

Font.register({
  family: "Poppins",
  src: PoppinsBold,
});

const RetailPDF = () => {
  let productNum = 0;

  const products = [
    { name: "Paprika u pavlaci", unit: "1kg", price: 330 },
    { name: "Paprika u pavlaci", unit: "2kg", price: 650 },
    { name: "Domaća pavlaka", unit: "1kg", price: 300 },
    { name: "Domaća pavlaka", unit: "2kg", price: 590 },
    { name: "Seckana paprika u pavlaci", unit: "250gr", price: 150 },
    { name: "Seckana paprika u pavlaci", unit: "400gr", price: 220 },
    { name: "Seckana paprika u pavlaci", unit: "1kg", price: 370 },
    { name: "Kajmak namaz", unit: "150gr", price: 130 },
    { name: "Kajmak namaz", unit: "250gr", price: 200 },
    { name: "Kajmak namaz", unit: "400gr", price: 300 },
    { name: "Kajmak namaz", unit: "500gr", price: 350 },
    { name: "Metanica", unit: "500gr", price: 550 },
    { name: "Surutka", unit: "1l", price: 40 },
    { name: "Surutka", unit: "2l", price: 80 },
    { name: "Sir kriška", unit: "1kg", price: 650 },
    { name: "Pavlaka (refuz)", unit: "1kg", price: 280 },
    { name: "Kajmak (mladi)", unit: "500gr", price: 500 },
    { name: "Kajmak (bivoleći)", unit: "1kg", price: 1500 },
    { name: "Sir (posni)", unit: "1kg", price: 300 },
    { name: "Sir (mladi)", unit: "1kg", price: 350 },
    { name: "Sir (kaca)", unit: "1kg", price: 650 },
    { name: "Pavlaka", unit: "5kg", price: 1450 },
    { name: "Topljeno maslo", unit: "2kg", price: 1500 },
  ];
  return (
    <Document title="Maloprodajni Cenovnik">
      <Page
        size="A4"
        style={{
          backgroundColor: "#fff",
          fontFamily: "Poppins",
        }}
      >
        <Image
          src={logo}
          style={{
            width: toSizePDF(60),
            marginTop: toSizePDF(5),
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <View
          style={{
            textAlign: "center",
          }}
        >
          <View
            style={{
              fontSize: 12,
              marginTop: toSizePDF(10),
            }}
          >
            <Text>Selo Ivanča bb Novi Pazar</Text>
            <Text>PIB: 108936574, MB: 63808105, Tr. 2015-219695-21</Text>
            <Text>Tel: 062 8909 699</Text>
          </View>
          <View
            style={{
              fontSize: 24,
              fontWeight: 900,
              marginTop: toSizePDF(13),
            }}
          >
            <Text>Maloprodajni Cenovnik</Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 12,
            fontWeight: 700,
            justifyContent: "center",
            marginTop: toSizePDF(17),
          }}
        >
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(30),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Redni broj</Text>
          </View>
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(100),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              paddingLeft: "10px",
            }}
          >
            <Text>Naziv robe</Text>
          </View>
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(40),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Jedinica mere</Text>
          </View>
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(30),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Cena</Text>
          </View>
        </View>
        {products &&
          products.map((product) => {
            productNum += 1;
            const fill = productNum % 2 === 1;
            const productName = product.name;
            const productUnit = product.unit;
            const productPrice = product.price;
            return (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 12,
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(30),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productNum}</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(100),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text>{productName}</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(40),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productUnit}</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(30),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productPrice} RSD</Text>
                  </View>
                </View>
              </>
            );
          })}
      </Page>
    </Document>
  );
};

export default RetailPDF;
