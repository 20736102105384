import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import FormSelect from "../../components/form/FormSelect";
import FormInput from "../../components/form/FormInput";
import FormError from "../../components/form/FormError";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import Form from "../../components/form/Form";
import toDateFormated from "../../functions/toDateFormated";

const BusinessAdd = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [businessCategory, setBusinessCategory] = useState("income");
  const [businessType, setBusinessType] = useState("local");
  const [businessDate, setBusinessDate] = useState(toDateFormated());
  const [businessAmount, setBusinessAmount] = useState();
  const [businessDescription, setBusinessDescription] = useState();

  useEffect(() => {
    if (businessCategory === "income") {
      setBusinessType("local");
    } else if (businessCategory === "expense") {
      setBusinessType("regular");
    }
  }, [businessCategory]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      category: businessCategory,
      type: businessType,
      date: businessDate,
      amount: businessAmount,
      description: businessDescription,
    };
    const response = await apiRequest.post("/business/add", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/business/view";
    } else {
      setFormError(response.error);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Poslovanje - Dodaj</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormSelect
            value={businessCategory}
            placeholder={"Vrsta"}
            options={[
              { placeholder: "Prihod", value: "income" },
              { placeholder: "Rashod", value: "expense" },
            ]}
            onChange={(e) => {
              setBusinessCategory(e.target.value);
            }}
          />
          <FormSelect
            value={businessType}
            placeholder={"Tip"}
            options={
              businessCategory === "income"
                ? [
                    { placeholder: "Lokal", value: "local" },
                    { placeholder: "Veleprodaja", value: "wholesale" },
                    { placeholder: "Ostalo", value: "other" },
                  ]
                : [
                    { placeholder: "Redovni", value: "regular" },
                    { placeholder: "Vandredni", value: "unregular" },
                    { placeholder: "Lokal", value: "local" },
                  ]
            }
            onChange={(e) => {
              handleFormChange();
              setBusinessType(e.target.value);
            }}
          />
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={businessDate}
            placeholder={"Datum"}
            onChange={(e) => {
              setBusinessDate(e.target.value);
            }}
          />
          <FormInput
            type="number"
            icon={"scale-balanced"}
            value={businessAmount}
            placeholder={"Iznos (RSD)"}
            onChange={(e) => {
              const input = e.target.value;
              if (/^\d*\.?\d{0,0}$/.test(input)) {
                setBusinessAmount(input);
              }
            }}
          />
          <FormInput
            icon={"text"}
            value={businessDescription}
            placeholder={"Opis"}
            onChange={(e) => {
              setBusinessDescription(e.target.value);
            }}
          />

          {formError && <FormError>{formError}</FormError>}
          <FormSubmit
            onClick={() => handleFormSubmit()}
            isLoading={formLoading}
          >
            Dodaj
          </FormSubmit>
        </Form>
      </SectionContent>
    </Section>
  );
};

export default BusinessAdd;
