import React from "react";

const SectionContent = ({ children }) => {
  return (
    <div className="pb-6 pt-5 px-4 w-full xs:pb-8 xs:pt-7 xs:px-6 sm:pb-10 sm:pt-9 sm:px-8 xl:pb-14 xl:pt-12 xl:px-16">
      {children}
    </div>
  );
};

export default SectionContent;
