const tobusinesselect = (business) => {
  const businesselect =
    business &&
    business.map((business) => {
      let businessCategory = business.category;
      let businessType = business.type;
      if (businessCategory === "income") {
        businessCategory = "Prihod";
      }
      if (businessCategory === "expense") {
        businessCategory = "Rashod";
      }
      if (businessType === "local") {
        businessType = "Lokal";
      }
      if (businessType === "wholesale") {
        businessType = "Veleprodaja";
      }
      if (businessType === "other") {
        businessType = "Ostalo";
      }
      if (businessType === "regular") {
        businessType = "Redovni";
      }
      if (businessType === "unregular") {
        businessType = "Vanredni";
      }
      return {
        placeholder:
          businessCategory +
          " " +
          businessType +
          " - " +
          business.amount +
          " RSD",
        value: business._id,
      };
    });
  return businesselect;
};

export default tobusinesselect;
