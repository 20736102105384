import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackButter = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [butterID, setButterID] = useState();
  const [butterAmount, setButterAmount] = useState();
  const [package05ID, setPackage05ID] = useState();
  const [package05Amount, setPackage05Amount] = useState("");
  const [package1ID, setPackage1ID] = useState();
  const [package1Amount, setPackage1Amount] = useState("");
  const [package5ID, setPackage5ID] = useState();
  const [package5Amount, setPackage5Amount] = useState("");

  const {
    warehouseProducts: warehouseButter,
    warehouseRefetch: warehouseButterRefetch,
  } = useWarehouseProduct(
    `product=66097824ec44f58429269729&amount=unused&date_produced=${packingDate}`
  );
  const warehouseButterSelect = useProductSelect(warehouseButter, "kg");

  const {
    warehouseProducts: warehousePackage05,
    warehouseRefetch: warehousePackage05Refetch,
  } = useWarehouseProduct(
    `product=660f2fc249a1b746df531ac1&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage05Select = useProductSelect(warehousePackage05, "kom");

  const {
    warehouseProducts: warehousePackage1,
    warehouseRefetch: warehousePackage1Refetch,
  } = useWarehouseProduct(
    `product=660f2fd449a1b746df531ac3&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage1Select = useProductSelect(warehousePackage1, "kom");

  const {
    warehouseProducts: warehousePackage5,
    warehouseRefetch: warehousePackage5Refetch,
  } = useWarehouseProduct(
    `product=660f3021bad3b41bfbe674fb&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage5Select = useProductSelect(warehousePackage5, "kom");

  useEffect(() => {
    if (warehouseButter && warehouseButter.length > 0) {
      const initialWarehouseButter = warehouseButter[0];
      const initialWarehouseButterID = initialWarehouseButter._id;
      setButterID(initialWarehouseButterID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseButter]);

  useEffect(() => {
    if (warehousePackage05 && warehousePackage05.length > 0) {
      const initialWarehousePackage05 = warehousePackage05[0];
      const initialWarehousePackage05ID = initialWarehousePackage05._id;
      setPackage05ID(initialWarehousePackage05ID);
    }
  }, [warehousePackage05]);

  useEffect(() => {
    if (warehousePackage1 && warehousePackage1.length > 0) {
      const initialWarehousePackage1 = warehousePackage1[0];
      const initialWarehousePackage1ID = initialWarehousePackage1._id;
      setPackage1ID(initialWarehousePackage1ID);
    }
  }, [warehousePackage1]);

  useEffect(() => {
    if (warehousePackage5 && warehousePackage5.length > 0) {
      const initialWarehousePackage5 = warehousePackage5[0];
      const initialWarehousePackage5ID = initialWarehousePackage5._id;
      setPackage5ID(initialWarehousePackage5ID);
    }
  }, [warehousePackage5]);

  useEffect(() => {
    warehouseButterRefetch();
    warehousePackage05Refetch();
    warehousePackage1Refetch();
    warehousePackage5Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (package05Amount > 0 || package1Amount > 0 || package5Amount > 0) {
      setIsProduceable(true);
      setButterAmount(
        package05Amount * 0.5 + package1Amount * 1 + package5Amount * 5
      );
    } else {
      setIsProduceable(false);
      setButterAmount(0);
    }
  }, [package05Amount, package1Amount, package5Amount]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseButter.find(
      (product) => product._id === butterID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 0.5 ? 0 : package1Amount * 0.5) -
        (packageSize === 1 ? 0 : package1Amount * 1) -
        (packageSize === 5 ? 0 : package5Amount * 5)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage05AmountChange = (input) => {
    const selectedPackage05 = warehousePackage05.find(
      (product) => product._id === package05ID
    );

    const maxPackageAmount05 =
      selectedPackage05.amount.produced - selectedPackage05.amount.used;

    const packageAmount = maxPackageSizeAmount(0.5);

    if (input > maxPackageAmount05) {
      if (maxPackageAmount05 > packageAmount) {
        setPackage05Amount(packageAmount);
      } else {
        setPackage05Amount(maxPackageAmount05);
      }
    } else {
      if (input > packageAmount) {
        setPackage05Amount(packageAmount);
      } else {
        setPackage05Amount(input);
      }
    }
  };

  const handlePackage1AmountChange = (input) => {
    const selectedPackage1 = warehousePackage1.find(
      (product) => product._id === package1ID
    );

    const maxPackageAmount1 =
      selectedPackage1.amount.produced - selectedPackage1.amount.used;

    const packageAmount = maxPackageSizeAmount(1);

    if (input > maxPackageAmount1) {
      if (maxPackageAmount1 > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(maxPackageAmount1);
      }
    } else {
      if (input > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(input);
      }
    }
  };

  const handlePackage5AmountChange = (input) => {
    const selectedPackage5 = warehousePackage5.find(
      (product) => product._id === package5ID
    );

    const maxPackageAmount5 =
      selectedPackage5.amount.produced - selectedPackage5.amount.used;

    const packageAmount = maxPackageSizeAmount(5);

    if (input > maxPackageAmount5) {
      if (maxPackageAmount5 > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(maxPackageAmount5);
      }
    } else {
      if (input > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 180);
    if (package05Amount > 0) {
      produced.push({
        product: "661011bafac9b067b8f69773",
        product_type: "Product",
        amount: package05Amount,
      });
      used.push({ product: package05ID, amount: package05Amount });
    }
    if (package1Amount > 0) {
      produced.push({
        product: "661011d5fac9b067b8f69774",
        product_type: "Product",
        amount: package1Amount,
      });
      used.push({ product: package1ID, amount: package1Amount });
    }
    if (package5Amount > 0) {
      produced.push({
        product: "661011e3fac9b067b8f69775",
        product_type: "Product",
        amount: package5Amount,
      });
      used.push({ product: package5ID, amount: package5Amount });
    }
    used.push({
      product: butterID,
      amount: butterAmount,
    });
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Maslac</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseButter && warehouseButter.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Maslac"}
                  options={warehouseButterSelect}
                  value={butterID}
                  onChange={(e) => {
                    setButterID(e.target.value);
                  }}
                />

                <FormInputHolder>
                  {warehousePackage05 && warehousePackage05.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 0.5kg"}
                        options={warehousePackage05Select}
                        value={package05ID}
                        onChange={(e) => {
                          setButterID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package05Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage05AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 0.5kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 0.5kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage1 && warehousePackage1.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 1kg"}
                        options={warehousePackage1Select}
                        value={package1ID}
                        onChange={(e) => {
                          setButterID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package1Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage1AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 1kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 1kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage5 && warehousePackage5.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 5kg"}
                        options={warehousePackage5Select}
                        value={package5ID}
                        onChange={(e) => {
                          setButterID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package5Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage5AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 5kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 5kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>Nedostaje Maslac za Pakovanje</FormError>
                <FormLink to={"/production/produce/cheese-skimmed-young"}>
                  Proizvedi Maslac
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {butterAmount > 0 && (
            <FormNotice>Spakuj {butterAmount}kg Maslac</FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackButter;
