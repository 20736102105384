const toPackageSelect = (productPackages) => {
  const productPackageSelect =
    productPackages &&
    productPackages.map((productPackage) => {
      return {
        placeholder:
          productPackage.name +
          " - " +
          productPackage.amount +
          productPackage.unit,
        value: productPackage._id,
      };
    });
  return productPackageSelect;
};

export default toPackageSelect;
