import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import FormSelect from "../../components/form/FormSelect";
import SectionHeading from "../../components/typography/SectionHeading";
import useMaterial from "../../hooks/material/useMaterial";
import toMaterialSelect from "../../functions/toMaterialSelect";
import LoadingData from "../../components/data/LoadingData";
import FormLink from "../../components/form/FormLink";

const MaterialEdit = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [productMaterialID, setProductMaterialID] = useState();
  const [productMaterialName, setProductMaterialName] = useState();

  const { productMaterials, productMaterialsLoading } = useMaterial();
  const productMaterialSelect = toMaterialSelect(productMaterials);

  const handleProductMaterialChange = (productMaterial) => {
    setProductMaterialID(productMaterial._id);
    setProductMaterialName(productMaterial.name);
  };

  useEffect(() => {
    if (productMaterials && productMaterials.length > 0) {
      const initialProductMaterial = productMaterials[0];
      handleProductMaterialChange(initialProductMaterial);
    }
  }, [productMaterials]);

  useEffect(() => {
    if (productMaterialID) {
      const selectedProduct = productMaterials.find(
        (productMaterial) => productMaterial._id === productMaterialID
      );
      handleProductMaterialChange(selectedProduct);
    }
  }, [productMaterialID]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      id: productMaterialID,
      name: productMaterialName,
    };
    const response = await apiRequest.post("/material/edit", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/material/view";
    } else {
      setFormError(response.error);
    }
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Uredi Materijale</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productMaterialsLoading ? (
          <LoadingData />
        ) : (
          <Form>
            {productMaterials && productMaterials.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Materijal"}
                  options={productMaterialSelect}
                  onChange={(e) => {
                    setProductMaterialID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"bag-seedling"}
                  placeholder={"Naziv"}
                  value={productMaterialName}
                  onChange={(e) => {
                    setProductMaterialName(e.target.value);
                  }}
                />
                {formError && <FormError>{formError}</FormError>}
                <FormSubmit
                  onClick={() => handleFormSubmit()}
                  isLoading={formLoading}
                >
                  Uredi
                </FormSubmit>
              </>
            ) : (
              <>
                <FormError>Nema Materijala za Uređivanje</FormError>
                <FormLink to="/material/add">Dodaj Materijal</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default MaterialEdit;
