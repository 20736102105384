import React, { useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";

const DriverAdd = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [driverFirstName, setDriverFirstName] = useState();
  const [driverLastName, setDriverLastName] = useState();
  const [driverCity, setDriverCity] = useState();
  const [driverStreet, setDriverStreet] = useState();
  const [driverStreetNumber, setDriverStreetNumber] = useState();
  const [driverPhoneNumber, setDriverPhoneNumber] = useState();
  const [driverUsername, setDriverUsername] = useState();
  const [driverPassword, setDriverPassword] = useState();

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      first_name: driverFirstName,
      last_name: driverLastName,
      username: driverUsername,
      password: driverPassword,
      contact: {
        address: {
          city: driverCity,
          street: driverStreet,
          street_number: driverStreetNumber,
        },
        phone_number: driverPhoneNumber,
      },
    };
    const response = await apiRequest.post("/driver/add", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/driver/view";
    } else {
      setFormError(response.error);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Vozača</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            icon={"user"}
            placeholder={"Ime"}
            onChange={(e) => {
              handleFormChange();
              setDriverFirstName(e.target.value);
            }}
          />
          <FormInput
            icon={"user"}
            placeholder={"Prezime"}
            onChange={(e) => {
              handleFormChange();
              setDriverLastName(e.target.value);
            }}
          />
          <FormInput
            icon={"city"}
            placeholder={"Grad"}
            onChange={(e) => {
              handleFormChange();
              setDriverCity(e.target.value);
            }}
          />
          <FormInput
            icon={"road"}
            placeholder={"Ulica"}
            onChange={(e) => {
              handleFormChange();
              setDriverStreet(e.target.value);
            }}
          />
          <FormInput
            icon={"hashtag"}
            placeholder={"Broj ulice"}
            onChange={(e) => {
              handleFormChange();
              setDriverStreetNumber(e.target.value);
            }}
          />
          <FormInput
            icon={"phone"}
            placeholder={"Broj Telefona"}
            onChange={(e) => {
              handleFormChange();
              setDriverPhoneNumber(e.target.value);
            }}
          />

          <FormInput
            icon={"at"}
            placeholder={"Korisničko Ime"}
            onChange={(e) => {
              handleFormChange();
              setDriverUsername(e.target.value);
            }}
          />
          <FormInput
            icon={"key"}
            placeholder={"Lozinka"}
            onChange={(e) => {
              handleFormChange();
              setDriverPassword(e.target.value);
            }}
          />
          {formError ? (
            <FormError>{formError}</FormError>
          ) : (
            <FormSubmit
              onClick={() => handleFormSubmit()}
              isLoading={formLoading}
            >
              Dodaj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default DriverAdd;
