import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import useCookie from "../../../hooks/useCookie";
import toDateFormated from "../../../functions/toDateFormated";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import apiRequest from "../../../api/apiRequest";
import FormLink from "../../../components/form/FormLink";

const ProduceCream40 = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProduceable, setIsProduceable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");
  const [milkID, setMilkID] = useState();
  const [milkAmount, setMilkAmount] = useState();

  const [producesCream, setProducesCream] = useState();
  const [producesMilkSkimmed, setProducesMilkSkimmed] = useState();

  const {
    warehouseProducts: warehouseMilk,
    warehouseRefetch: warehouseMilkRefetch,
  } = useWarehouseProduct(
    `product=66076561c2ac8af5ca0bcca0&amount=unused&date_produced=${productionDate}`
  );
  const warehouseMilkSelect = useProductSelect(warehouseMilk, "l");

  useEffect(() => {
    if (warehouseMilk && warehouseMilk.length > 0) {
      const initialWarehouseMilk = warehouseMilk[0];
      const initialWarehouseMilkID = initialWarehouseMilk._id;
      setMilkID(initialWarehouseMilkID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseMilk]);

  useEffect(() => {
    if (milkID) {
      const selectedProduct = warehouseMilk.find(
        (product) => product._id === milkID
      );
      const maxProductAmount =
        selectedProduct.amount.produced - selectedProduct.amount.used;
      if (milkAmount > maxProductAmount) {
        setMilkAmount(maxProductAmount);
      } else {
        setMilkAmount(milkAmount);
      }
    }
  }, [milkAmount]);

  useEffect(() => {
    if (milkAmount && milkAmount > 0) {
      const producesCream = toFixedNumber((milkAmount * 4) / 40);
      setIsProduceable(true);
      setProducesCream(producesCream);
      setProducesMilkSkimmed(toFixedNumber(milkAmount - producesCream));
    } else {
      setIsProduceable(false);
      setProducesCream("");
      setProducesMilkSkimmed("");
    }
  }, [milkAmount]);

  useEffect(() => {
    warehouseMilkRefetch();
  }, [productionDate]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "6609f649da936024ef03345b",
          product_type: "ProductRaw",
          amount: producesMilkSkimmed,
        },
        {
          product: "6608b3b9a98f69aa0dab1431",
          product_type: "ProductRaw",
          amount: producesCream,
        },
      ],
      used: [{ product: milkID, amount: milkAmount }],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>
          Proizvedi - Pavlaka Poluproizvod 40% m.m
        </SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseMilk && warehouseMilk.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Mleko"}
                  options={warehouseMilkSelect}
                  value={milkID}
                  onChange={(e) => {
                    setMilkID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (L)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={milkAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      setMilkAmount(toFixedNumber(parseFloat(input)));
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje Mleko za Proizvodnju</FormError>
                <FormLink to={"/milk/add"}>Dodaj Mleko</FormLink>
              </>
            )}
          </FormInputHolder>
          {producesCream && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesCream}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesCream(input);
                  }
                }}
              />
              <FormNotice>
                Proizvedi {producesCream}kg Pavlake Poluproizvoda 40% m.m
              </FormNotice>
            </FormInputHolder>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Proizvedi
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceCream40;
