import React from "react";
import { Outlet } from "react-router-dom";

const Main = () => {
  return (
    <main className="flex items-center justify-center flex-1 p-4 xs:p-6 sm:p-8 md:p-10 lg:p-12 xl:p-16 2xl:p-20">
      <div className="max-w-screen-lg w-full">
        <Outlet />
      </div>
    </main>
  );
};

export default Main;
