import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackCheeseSkimmedYoung = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [cheeseSkimmedYoungID, setCheeseSkimmedYoungID] = useState();
  const [cheeseSkimmedYoungAmount, setCheeseSkimmedYoungAmount] = useState();
  const [package5ID, setPackage5ID] = useState();
  const [package5Amount, setPackage5Amount] = useState("");
  const [package10ID, setPackage10ID] = useState();
  const [package10Amount, setPackage10Amount] = useState("");

  const {
    warehouseProducts: warehouseCheeseSkimmedYoung,
    warehouseRefetch: warehouseCheeseSkimmedYoungRefetch,
  } = useWarehouseProduct(
    `product=66097546ec44f58429269725&amount=unused&date_produced=${packingDate}`
  );
  const warehouseCheeseSkimmedYoungSelect = useProductSelect(
    warehouseCheeseSkimmedYoung,
    "kg"
  );

  const {
    warehouseProducts: warehousePackage5,
    warehouseRefetch: warehousePackage5Refetch,
  } = useWarehouseProduct(
    `product=660f3021bad3b41bfbe674fb&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage5Select = useProductSelect(warehousePackage5, "kom");

  const {
    warehouseProducts: warehousePackage10,
    warehouseRefetch: warehousePackage10Refetch,
  } = useWarehouseProduct(
    `product=660f3028bad3b41bfbe674fc&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage10Select = useProductSelect(warehousePackage10, "kom");

  useEffect(() => {
    if (warehouseCheeseSkimmedYoung && warehouseCheeseSkimmedYoung.length > 0) {
      const initialWarehouseCheeseSkimmedYoung = warehouseCheeseSkimmedYoung[0];
      const initialWarehouseCheeseSkimmedYoungID =
        initialWarehouseCheeseSkimmedYoung._id;
      setCheeseSkimmedYoungID(initialWarehouseCheeseSkimmedYoungID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCheeseSkimmedYoung]);

  useEffect(() => {
    if (warehousePackage5 && warehousePackage5.length > 0) {
      const initialWarehousePackage5 = warehousePackage5[0];
      const initialWarehousePackage5ID = initialWarehousePackage5._id;
      setPackage5ID(initialWarehousePackage5ID);
    }
  }, [warehousePackage5]);

  useEffect(() => {
    if (warehousePackage10 && warehousePackage10.length > 0) {
      const initialWarehousePackage10 = warehousePackage10[0];
      const initialWarehousePackage10ID = initialWarehousePackage10._id;
      setPackage10ID(initialWarehousePackage10ID);
    }
  }, [warehousePackage10]);

  useEffect(() => {
    warehouseCheeseSkimmedYoungRefetch();
    warehousePackage5Refetch();
    warehousePackage10Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (package5Amount > 0 || package10Amount > 0) {
      setIsProduceable(true);
      setCheeseSkimmedYoungAmount(package5Amount * 5 + package10Amount * 10);
    } else {
      setIsProduceable(false);
      setCheeseSkimmedYoungAmount(0);
    }
  }, [package5Amount, package10Amount]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseCheeseSkimmedYoung.find(
      (product) => product._id === cheeseSkimmedYoungID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 5 ? 0 : package5Amount * 5) -
        (packageSize === 10 ? 0 : package10Amount * 10)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage5AmountChange = (input) => {
    const selectedPackage5 = warehousePackage5.find(
      (product) => product._id === package5ID
    );

    const maxPackageAmount5 =
      selectedPackage5.amount.produced - selectedPackage5.amount.used;

    const packageAmount = maxPackageSizeAmount(5);

    if (input > maxPackageAmount5) {
      if (maxPackageAmount5 > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(maxPackageAmount5);
      }
    } else {
      if (input > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(input);
      }
    }
  };

  const handlePackage10AmountChange = (input) => {
    const selectedPackage10 = warehousePackage10.find(
      (product) => product._id === package10ID
    );

    const maxPackageAmount10 =
      selectedPackage10.amount.produced - selectedPackage10.amount.used;

    const packageAmount = maxPackageSizeAmount(10);

    if (input > maxPackageAmount10) {
      if (maxPackageAmount10 > packageAmount) {
        setPackage10Amount(packageAmount);
      } else {
        setPackage10Amount(maxPackageAmount10);
      }
    } else {
      if (input > packageAmount) {
        setPackage10Amount(packageAmount);
      } else {
        setPackage10Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 45);
    if (package5Amount > 0) {
      produced.push({
        product: "6610109cfac9b067b8f69769",
        product_type: "Product",
        amount: package5Amount,
      });
      used.push({ product: package5ID, amount: package5Amount });
    }
    used.push({
      product: cheeseSkimmedYoungID,
      amount: cheeseSkimmedYoungAmount,
    });
    if (package10Amount > 0) {
      produced.push({
        product: "661010bafac9b067b8f6976a",
        product_type: "Product",
        amount: package10Amount,
      });
      used.push({ product: package10ID, amount: package10Amount });
    }
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Obrani Mladi Sir</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCheeseSkimmedYoung &&
            warehouseCheeseSkimmedYoung.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Obrani Mladi Sir"}
                  options={warehouseCheeseSkimmedYoungSelect}
                  value={cheeseSkimmedYoungID}
                  onChange={(e) => {
                    setCheeseSkimmedYoungID(e.target.value);
                  }}
                />

                <FormInputHolder>
                  {warehousePackage5 && warehousePackage5.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 5kg"}
                        options={warehousePackage5Select}
                        value={package5ID}
                        onChange={(e) => {
                          setCheeseSkimmedYoungID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package5Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage5AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 5kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 5kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage10 && warehousePackage10.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 10kg"}
                        options={warehousePackage10Select}
                        value={package10ID}
                        onChange={(e) => {
                          setCheeseSkimmedYoungID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package10Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage10AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 10kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 10kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>Nedostaje Obrani Mladi Sir za Pakovanje</FormError>
                <FormLink to={"/production/produce/cheese-skimmed-young"}>
                  Proizvedi Obrani Mladi Sir
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {cheeseSkimmedYoungAmount > 0 && (
            <FormNotice>
              Spakuj {cheeseSkimmedYoungAmount}kg Obrani Mladi Sir
            </FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackCheeseSkimmedYoung;
