import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import useCookie from "../../../hooks/useCookie";
import toDateFormated from "../../../functions/toDateFormated";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import apiRequest from "../../../api/apiRequest";
import FormLink from "../../../components/form/FormLink";

const ProduceWhey = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProduceable, setIsProduceable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");

  const [wheyRawID, setWheyRawID] = useState();
  const [wheyRawAmount, setWheyRawAmount] = useState();

  const [starterCultureID, setStarterCultureID] = useState();
  const [starterCultureAmount, setStarterCultureAmount] = useState();

  const [producesWhey, setProducesWhey] = useState();

  const {
    warehouseProducts: warehouseWheyRaw,
    warehouseRefetch: warehouseWheyRawRefetch,
  } = useWarehouseProduct(
    `product=660df7184733ba77dbf02d82&amount=unused&date_produced=${productionDate}`
  );
  const warehouseWheyRawSelect = useProductSelect(warehouseWheyRaw, "l");

  const {
    warehouseProducts: warehouseStarterCulture,
    warehouseRefetch: warehouseStarterCultureRefetch,
  } = useWarehouseProduct(
    `product=660df9d1b81436333911baa7&amount=unused&date_produced=${productionDate}`
  );
  const warehouseStarterCultureSelect = useProductSelect(
    warehouseStarterCulture,
    "kom"
  );

  useEffect(() => {
    if (warehouseWheyRaw && warehouseWheyRaw.length > 0) {
      const initialWarehouseWheyRaw = warehouseWheyRaw[0];
      const initialWarehouseWheyRawID = initialWarehouseWheyRaw._id;
      setWheyRawID(initialWarehouseWheyRawID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseWheyRaw]);

  useEffect(() => {
    if (wheyRawID) {
      const selectedProduct = warehouseWheyRaw.find(
        (product) => product._id === wheyRawID
      );
      const maxProductAmount =
        selectedProduct.amount.produced - selectedProduct.amount.used;
      if (wheyRawAmount > maxProductAmount) {
        setWheyRawAmount(maxProductAmount);
      } else {
        setWheyRawAmount(wheyRawAmount);
      }
    }
  }, [wheyRawAmount]);

  useEffect(() => {
    if (warehouseStarterCulture && warehouseStarterCulture.length > 0) {
      const initialWarehouseStarterCulture = warehouseStarterCulture[0];
      const initialWarehouseStarterCultureID =
        initialWarehouseStarterCulture._id;
      setStarterCultureID(initialWarehouseStarterCultureID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseStarterCulture]);

  useEffect(() => {
    if (starterCultureID) {
      const selectedProduct = warehouseStarterCulture.find(
        (product) => product._id === starterCultureID
      );
      const maxProductAmount =
        selectedProduct.amount.produced - selectedProduct.amount.used;
      if (starterCultureAmount > maxProductAmount) {
        setStarterCultureAmount(maxProductAmount);
      } else {
        setStarterCultureAmount(starterCultureAmount);
      }
    }
  }, [starterCultureAmount]);

  useEffect(() => {
    if (
      wheyRawAmount &&
      wheyRawAmount > 0 &&
      starterCultureAmount &&
      starterCultureAmount > 0
    ) {
      const producesWhey = toFixedNumber(wheyRawAmount);
      setIsProduceable(true);
      setProducesWhey(producesWhey);
    } else {
      setIsProduceable(false);
      setProducesWhey("");
    }
  }, [wheyRawAmount, starterCultureAmount]);

  useEffect(() => {
    warehouseWheyRawRefetch();
    warehouseStarterCultureRefetch();
  }, [productionDate]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "66097884ec44f5842926972d",
          product_type: "ProductRaw",
          amount: producesWhey,
        },
      ],
      used: [
        { product: wheyRawID, amount: wheyRawAmount },
        { product: starterCultureID, amount: starterCultureAmount },
      ],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Surutku</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseWheyRaw && warehouseWheyRaw.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Surutka Poluproizvod"}
                  options={warehouseWheyRawSelect}
                  value={wheyRawID}
                  onChange={(e) => {
                    setWheyRawID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (L)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={wheyRawAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      setWheyRawAmount(toFixedNumber(parseFloat(input)));
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje Mleko za Proizvodnju</FormError>
                <FormLink to={"/wheyRaw/add"}>Dodaj Mleko</FormLink>
              </>
            )}
          </FormInputHolder>

          <FormInputHolder>
            {warehouseStarterCulture && warehouseStarterCulture.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Starter Kultura"}
                  options={warehouseStarterCultureSelect}
                  value={starterCultureID}
                  onChange={(e) => {
                    setWheyRawID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (KOM)"}
                  type="number"
                  step="1"
                  min={1}
                  value={starterCultureAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    setStarterCultureAmount(parseInt(input, 10));
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje Starter Kultura za Proizvodnju</FormError>
                <FormLink to={"/warehouse/add/material"}>
                  Dodaj Starter Kulturu
                </FormLink>
              </>
            )}
          </FormInputHolder>

          {producesWhey && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesWhey}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesWhey(input);
                  }
                }}
              />
              <FormNotice>Proizvedi {producesWhey}l Surutke</FormNotice>
            </FormInputHolder>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Proizvedi
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceWhey;
