import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useContext, useState } from "react";
import toSizePDF from "../../../functions/toSizePDF";
import toDateReadable from "../../../functions/toDateReadable";

import logo from "../../../assets/media/logo.png";
import PoppinsRegular from "../../../assets/fonts/PoppinsRegular-400.ttf";
import PoppinsMedium from "../../../assets/fonts/PoppinsMedium-500.ttf";
import PoppinsBold from "../../../assets/fonts/PoppinsBold-700.ttf";
import { MilkPrintContext } from "../../../context/MilkPrintContextProvider";
import toDateFormated from "../../../functions/toDateFormated";
import toFixedNumber from "../../../functions/toFixedNumber";
import Form from "../../../components/form/Form";
import FormInput from "../../../components/form/FormInput";
import FormInputHolder from "../../../components/form/FormInputHolder";

Font.register({
  family: "Poppins",
  fontWeight: 400,
  src: PoppinsRegular,
});

Font.register({
  family: "Poppins",
  fontWeight: 500,
  src: PoppinsMedium,
});

Font.register({
  family: "Poppins",
  fontWeight: 700,
  src: PoppinsBold,
});

function calculateSum(data) {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum += data[i].amount;
  }
  return sum;
}

const CertificatePDF = ({
  farmers,
  milks,
  milkFarmPrice,
  startDate,
  endDate,
  certificateStart,
  milkQualities,
}) => {
  let farmerNum = 0;

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const dates = [];

  for (
    let date = new Date(startDateObj);
    date <= endDateObj;
    date.setDate(date.getDate() + 1)
  ) {
    date.setHours(0, 0, 0, 0);
    dates.push(toDateFormated(new Date(date)));
  }
  return (
    <Document
      title={`Mleko Priznanice - ( ${toDateReadable(
        new Date(startDate)
      )} - ${toDateReadable(new Date(endDate))} )`}
    >
      {farmers.map((farmer) => {
        const farmerFarm = farmer.farm;
        if (farmerFarm) {
          const farmerID = farmer._id;
          const farmerUIN = farmer.uin;
          const farmerFirstName = farmer.first_name;
          const farmerLastName = farmer.last_name;
          const farmerContact = farmer.contact;
          const farmerAddress = farmerContact.address;
          const farmerAddressCity = farmerAddress.city;
          const farmerAdressStreet = farmerAddress.street;
          const farmerAdressStreetNumber = farmerAddress.street_number;
          const farmerPhoneNumber = farmerContact.phone_number;
          const farmerMilks = milks.filter(
            (milk) => milk.farmer._id === farmerID
          );
          if (
            farmerMilks &&
            farmerMilks.length > 0 &&
            calculateSum(farmerMilks) > 0
          ) {
            let milkTotal = 0;
            const milkQuality = milkQualities[farmerID] ?? 4;
            const milkQualityPrice = milkQuality * (milkFarmPrice / 4);
            dates.map((date) => {
              const milkDates = farmerMilks.filter(
                (milk) => toDateFormated(new Date(milk.date)) === date
              );
              const milkDate = milkDates[0];
              const milkAmount = milkDate ? milkDate.amount : 0;

              milkTotal += milkAmount;
            });
            const priceTotal = milkTotal * milkQualityPrice;
            const priceNoTax = toFixedNumber((priceTotal * 100) / 108);
            const priceTax = toFixedNumber(priceTotal - priceNoTax);

            farmerNum += 1;
            return (
              <Page
                size={{ width: toSizePDF(210), height: toSizePDF(148.5) }}
                style={{
                  backgroundColor: "#fff",
                  fontFamily: "Poppins",
                }}
              >
                <View
                  style={{
                    marginTop: toSizePDF(5),
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Image
                    src={logo}
                    style={{
                      width: 100,
                    }}
                  />
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 10,
                    marginTop: toSizePDF(5),
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 700,
                        marginBottom: toSizePDF(2),
                      }}
                    >
                      Podaci o otkupljivaču
                    </Text>
                    <View
                      style={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text>Naziv: PR Mlekara Eco Milk Pešter</Text>
                      <Text>Adresa: Ivanča 54, 36308</Text>
                      <Text>Mesto: Novi Pazar</Text>
                      <Text>PIB: 113813367</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: 700,
                        marginBottom: toSizePDF(2),
                      }}
                    >
                      Otkup od poljoprivrednika
                    </Text>
                    <View
                      style={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text>
                        Ime i Prezime: {farmerFirstName} {farmerLastName}
                      </Text>
                      <Text>
                        Adresa: {farmerAdressStreet}{" "}
                        {farmerAdressStreetNumber
                          ? farmerAdressStreetNumber
                          : "bb"}
                      </Text>
                      <Text>Mesto: {farmerAddressCity}</Text>
                      <Text>JMBG: {farmerUIN}</Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 10,
                    marginTop: toSizePDF(5),
                  }}
                >
                  <Text style={{ fontSize: 15, fontWeight: 700 }}>
                    Otkupni list - priznanica br.{" "}
                    {certificateStart - 1 + farmerNum}
                  </Text>
                  <Text>Za otkupljene poljoprivredne proizvode</Text>
                  <Text>
                    {toDateReadable(new Date(startDate))}
                    {" - "}
                    {toDateReadable(new Date(endDate))}
                  </Text>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 8,
                    margin: `0 ${toSizePDF(5)}`,
                    marginTop: toSizePDF(5),
                    fontWeight: 700,
                    backgroundColor: "#fafafa",
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(10),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Red. br.</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(20),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Naziv robe</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(12),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Jed. mere</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(15),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Količina</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(15),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Kvalitet</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(25),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Cena sa PDV nadoknadom</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(30),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Vrednost bez PDV nadoknade</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(23),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Stopa PDV nadoknade</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(23),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Iznos PDV nadoknade</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(27),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Vrednost sa PDV nadoknadom</Text>
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 8,
                    margin: `0 ${toSizePDF(5)}`,
                    fontWeight: 500,
                  }}
                >
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(10),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>1</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(20),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>Mleko</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(12),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>L</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(15),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>{milkTotal}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(15),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>{milkQuality}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(25),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>{milkFarmPrice}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(30),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>{priceNoTax}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(23),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>8%</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(23),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>{priceTax}</Text>
                  </View>
                  <View
                    style={{
                      display: "flex",
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #000",
                      width: toSizePDF(27),
                      height: toSizePDF(10),
                    }}
                  >
                    <Text>{priceTotal} RSD</Text>
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 8,
                    margin: `0 ${toSizePDF(15)}`,
                    marginTop: toSizePDF(8),
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text>Mesto i datum</Text>
                      <Text>Novi Pazar, {toDateReadable(new Date())}</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        fontWeight: 500,
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text>
                        Izjavljujem da sam obaveznik poreza na dohodak gradjana
                        na prihode od poljoprivrede i šumarstva na osnovu
                        katastarskog prihoda.
                      </Text>
                    </View>
                  </View>
                </View>

                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 8,
                    margin: `0 ${toSizePDF(15)}`,
                    marginTop: toSizePDF(5),
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={{ fontSize: 10, fontWeight: 700 }}>
                        Ovlašćeni otkupljivač
                      </Text>
                      <View
                        style={{
                          backgroundColor: "#000",
                          width: toSizePDF(60),
                          height: 1,
                          marginTop: toSizePDF(10),
                        }}
                      ></View>
                    </View>
                  </View>
                  <View
                    style={{
                      width: toSizePDF(5),
                      position: "absolute",
                      left: "50%",
                      top: toSizePDF(3),
                      transform: `translateX(-${toSizePDF(2.5)})`,
                    }}
                  >
                    <Text>M.P</Text>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        fontWeight: 500,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Text style={{ fontSize: 10, fontWeight: 700 }}>
                        Poljoprivrednik
                      </Text>
                      <View
                        style={{
                          backgroundColor: "#000",
                          width: toSizePDF(60),
                          height: 1,
                          marginTop: toSizePDF(10),
                        }}
                      ></View>
                    </View>
                  </View>
                </View>
              </Page>
            );
          }
        }
      })}
    </Document>
  );
};

export default CertificatePDF;
