import React from "react";
import NavLogo from "./nav/NavLogo";
import Nav from "./nav/Nav";
import { HeaderContextProvider } from "../../context/HeaderContextProvider";

const Header = () => {
  return (
    <HeaderContextProvider>
      <header className="border-theme-color--headerBorder flex items-center h-theme-height--header sticky top-0 w-full z-1001 max-xl:bg-theme-color--header max-xl:border-b xl:border-r xl:items-start xl:h-screen xl:w-96">
        <nav className="w-full xl:h-full">
          <ul className="flex items-center justify-between max-xl:p-4 xl:flex-col xl:h-full">
            <NavLogo />
            <Nav />
          </ul>
        </nav>
      </header>
    </HeaderContextProvider>
  );
};

export default Header;
