import toDateLot from "./toDateLot";
import toFixedNumber from "./toFixedNumber";

const toWarehouseSelect = (warehouses) => {
  const warehouseSelect =
    warehouses &&
    warehouses.map((warehouse) => {
      const smthn =
        warehouse.product_type === "Product"
          ? warehouse.product.package.amount + warehouse.product.package.unit
          : "";
      return {
        placeholder:
          toDateLot(new Date(warehouse.date.produced)) +
          " " +
          warehouse.product.name +
          " (" +
          smthn +
          ") - " +
          toFixedNumber(warehouse.amount.produced - warehouse.amount.used) +
          warehouse.product.unit,
        value: warehouse._id,
      };
    });
  return warehouseSelect;
};

export default toWarehouseSelect;
