import React from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import useFarmer from "../../hooks/farmer/useFarmer";
import Icon from "../../components/icon/Icon";
import LoadingData from "../../components/data/LoadingData";
import FormError from "../../components/form/FormError";
import FormLink from "../../components/form/FormLink";
import Form from "../../components/form/Form";
import ViewList from "../../components/list/ViewList";
import ViewItem from "../../components/list/ViewItem";
import ListItemIcon from "../../components/list/ListItemIcon";
import ListItemHeading from "../../components/list/ListItemHeading";
import ListItemFlexIconHeading from "../../components/list/ListItemFlexIconHeading";

const FarmerView = () => {
  const { farmers, farmersLoading } = useFarmer();
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pregledaj Farmere</SectionHeading>
      </SectionHead>
      <SectionContent>
        {farmersLoading ? (
          <LoadingData />
        ) : (
          <>
            {farmers && farmers.length > 0 ? (
              <ViewList>
                {farmers.map((farmer, key) => {
                  return (
                    <ViewItem
                      className={farmer.active ? "" : "!bg-red-700 text-white"}
                      key={key}
                    >
                      <ListItemFlexIconHeading>
                        <ListItemIcon icon={"user-cowboy"} />
                        <ListItemHeading>
                          {farmer.first_name + " " + farmer.last_name}
                        </ListItemHeading>
                      </ListItemFlexIconHeading>
                    </ViewItem>
                  );
                })}
              </ViewList>
            ) : (
              <Form>
                <FormError>Nema Farmera za Pregledavanje</FormError>
                <FormLink to={"/farmer/add"}>Dodaj Farmera</FormLink>
              </Form>
            )}
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default FarmerView;
