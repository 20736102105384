import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import Icon from "../../components/icon/Icon";

const PDFLink = ({ document, fileName, label }) => (
  <PDFDownloadLink
    document={document}
    fileName={fileName}
    className="bg-theme-color--primary flex-1 flex h-14 items-center justify-center text-theme-color--primaryContrast rounded-lg"
  >
    {({ loading }) => {
      return loading ? (
        <div className="animate-spin">
          <Icon icon={"spinner"} iconStyle="solid" />
        </div>
      ) : (
        <span>{label}</span>
      );
    }}
  </PDFDownloadLink>
);

export default PDFLink;
