import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import useCookie from "../../../hooks/useCookie";
import toDateFormated from "../../../functions/toDateFormated";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import apiRequest from "../../../api/apiRequest";
import FormLink from "../../../components/form/FormLink";

const ProduceMint = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProduceable, setIsProduceable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");
  const [cream70ID, setCream70ID] = useState();
  const [cream70Amount, setCream70Amount] = useState();

  const [producesMint, setProducesMint] = useState();
  const [producesCream70, setProducesCream70] = useState();

  const {
    warehouseProducts: warehouseCream70,
    warehouseRefetch: warehouseCream70Refetch,
  } = useWarehouseProduct(
    `product=66097524ec44f58429269723&amount=unused&date_produced=${productionDate}`
  );
  const warehouseCream70Select = useProductSelect(warehouseCream70, "kg");

  useEffect(() => {
    if (warehouseCream70 && warehouseCream70.length > 0) {
      const initialWarehouseCream70 = warehouseCream70[0];
      const initialWarehouseCream70ID = initialWarehouseCream70._id;
      setCream70ID(initialWarehouseCream70ID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCream70]);

  useEffect(() => {
    if (cream70ID) {
      const selectedProduct = warehouseCream70.find(
        (product) => product._id === cream70ID
      );
      const maxProductAmount =
        selectedProduct.amount.produced - selectedProduct.amount.used;
      if (cream70Amount > maxProductAmount) {
        setCream70Amount(maxProductAmount);
      } else {
        setCream70Amount(cream70Amount);
      }
    }
  }, [cream70Amount]);

  useEffect(() => {
    if (cream70Amount && cream70Amount > 0) {
      const producesMint = toFixedNumber((cream70Amount * 70) / 85);
      setIsProduceable(true);
      setProducesMint(producesMint);
      setProducesCream70(cream70Amount - producesMint);
    } else {
      setIsProduceable(false);
      setProducesMint("");
      setProducesCream70("");
    }
  }, [cream70Amount]);

  useEffect(() => {
    warehouseCream70Refetch();
  }, [productionDate]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "6609782eec44f5842926972a",
          product_type: "ProductRaw",
          amount: producesMint,
        },
      ],
      used: [{ product: cream70ID, amount: cream70Amount }],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Metanicu</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCream70 && warehouseCream70.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Pavlaka Poluproizvod 70% m.m"}
                  options={warehouseCream70Select}
                  value={cream70ID}
                  onChange={(e) => {
                    setCream70ID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"scale-balanced"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={cream70Amount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      setCream70Amount(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Pavlaka Poluproizvod 70% m.m za Proizvodnju
                </FormError>
                <FormLink to={"/production/produce/cream-70"}>
                  Proizvedi Pavlaku Poluproizvod 70% m.m
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {producesMint && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesMint}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesMint(input);
                  }
                }}
              />
              <FormNotice>Proizvedi {producesMint}kg Metanice</FormNotice>
            </FormInputHolder>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Proizvedi
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceMint;
