import React from "react";

const NavGroup = ({ title, children }) => {
  return (
    <li className="flex flex-col gap-4">
      <h1 className="font-medium text-lg px-2">{title}</h1>
      <ul className="border bg-slate-100 grid gap-3 p-4 rounded-lg sm:grid-cols-2 md:grid-cols-3 xl:flex xl:flex-col">
        {children}
      </ul>
    </li>
  );
};

export default NavGroup;
