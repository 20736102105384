import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../icon/Icon";

const NavItem = ({ to, icon, heading }) => {
  return (
    <li className="group">
      <Link to={to}>
        <div className="bg-theme-color--headerMenuItem border border-theme-color--headerMenuItemBorder flex gap-x-2 items-center p-4 py-6 rounded-lg group-hover:bg-theme-color--primary group-hover:text-theme-color--primaryContrast">
          <div className="flex items-center justify-center text-xl w-10">
            <Icon icon={icon} />
          </div>
          <h1>{heading}</h1>
        </div>
      </Link>
    </li>
  );
};

export default NavItem;
