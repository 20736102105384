import React from "react";

const FormNotice = ({ children }) => {
  return (
    <div className="bg-green-600 font-bold flex items-center justify-center p-3 rounded-lg text-white text-center xl:p-4 xl:text-lg">
      {children}
    </div>
  );
};

export default FormNotice;
