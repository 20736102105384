import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import useCookie from "../../../hooks/useCookie";
import apiRequest from "../../../api/apiRequest";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import toDateFormated from "../../../functions/toDateFormated";

const ProduceCheeseFresh = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProduceable, setIsProduceable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");
  const [producesCheeseFreshAmount, setProducesCheeseFreshAmount] = useState();
  const [producesWheyAmount, setProducesWheyAmount] = useState();

  const {
    warehouseProducts: warehouseWheyRaw,
    warehouseRefetch: warehouseWheyRawRefetch,
  } = useWarehouseProduct(
    `product=6609786bec44f5842926972c&amount=unused&date_produced=${productionDate}`
  );
  const warehouseWheyRawSelect = useProductSelect(warehouseWheyRaw, "L");

  const [wheyRawID, setWheyRawID] = useState();
  const [wheyRawAmount, setWheyRawAmount] = useState();

  const {
    warehouseProducts: warehouseSalt,
    warehouseRefetch: warehouseSaltRefetch,
  } = useWarehouseProduct(
    `product=660478b366de10b314ad7f17&amount=unused&date_produced=${productionDate}`
  );
  const warehouseSaltSelect = useProductSelect(warehouseSalt, "KG");

  const [saltID, setSaltID] = useState();
  const [saltAmount, setSaltAmount] = useState();

  useEffect(() => {
    if (warehouseWheyRaw && warehouseWheyRaw.length > 0) {
      const initialWarehouseWheyRaw = warehouseWheyRaw[0];
      const initialWarehouseWheyRawID = initialWarehouseWheyRaw._id;
      setWheyRawID(initialWarehouseWheyRawID);
    }
  }, [warehouseWheyRaw]);

  useEffect(() => {
    if (warehouseSalt && warehouseSalt.length > 0) {
      const initialWarehouseSalt = warehouseSalt[0];
      const initialWarehouseSaltID = initialWarehouseSalt._id;
      setSaltID(initialWarehouseSaltID);
    }
  }, [warehouseSalt]);

  useEffect(() => {
    handleReset();
  }, [wheyRawID, saltID]);

  const handleReset = () => {
    setWheyRawAmount("");
    setSaltAmount("");
  };

  const handleWheyRawChange = (input) => {
    if (input) {
      const selectedWheyRaw = warehouseWheyRaw.find(
        (product) => product._id === wheyRawID
      );
      const maxWheyRawAmount = toFixedNumber(
        selectedWheyRaw.amount.produced - selectedWheyRaw.amount.used
      );
      const wheyRawAmount = input > maxWheyRawAmount ? maxWheyRawAmount : input;

      const saltAmount = toFixedNumber((wheyRawAmount / 25) * 0.02);

      setWheyRawAmount(wheyRawAmount);
      setSaltAmount(saltAmount);
    } else {
      handleReset();
    }
  };

  const handleSaltChange = (input) => {
    if (input) {
      const selectedSalt = warehouseSalt.find(
        (product) => product._id === saltID
      );
      const maxSaltAmount = toFixedNumber(
        selectedSalt.amount.produced - selectedSalt.amount.used
      );
      const saltAmount = input > maxSaltAmount ? maxSaltAmount : input;
      const wheyRawAmount = toFixedNumber(saltAmount * (100 / 2) * 25);
      setSaltAmount(saltAmount);
      setWheyRawAmount(wheyRawAmount);
    } else {
      handleReset();
    }
  };

  useEffect(() => {
    if (warehouseWheyRaw && wheyRawID && warehouseSalt && saltID) {
      setIsProduceable(true);
      const selectedWheyRaw = warehouseWheyRaw.find(
        (product) => product._id === wheyRawID
      );
      const maxWheyRawAmount = toFixedNumber(
        selectedWheyRaw.amount.produced - selectedWheyRaw.amount.used
      );
      const selectedSalt = warehouseSalt.find(
        (product) => product._id === saltID
      );
      const maxSaltAmount = toFixedNumber(
        selectedSalt.amount.produced - selectedSalt.amount.used
      );
      if (wheyRawAmount > maxWheyRawAmount) {
        handleWheyRawChange(wheyRawAmount);
      } else if (saltAmount > maxSaltAmount) {
        handleSaltChange(saltAmount);
      }
    } else {
      setIsProduceable(false);
    }
  }, [
    warehouseWheyRaw,
    wheyRawID,
    wheyRawAmount,

    warehouseSalt,
    saltID,
    saltAmount,
  ]);

  useEffect(() => {
    const producesCheeseFreshAmount = toFixedNumber(wheyRawAmount / 25);
    const producesWheyAmount = toFixedNumber(
      wheyRawAmount - producesCheeseFreshAmount
    );
    if (producesCheeseFreshAmount && producesCheeseFreshAmount > 0) {
      setProducesCheeseFreshAmount(producesCheeseFreshAmount);
      setProducesWheyAmount(producesWheyAmount);
    } else {
      setProducesCheeseFreshAmount("");
      setProducesWheyAmount("");
    }
  }, [wheyRawAmount]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "66097840ec44f5842926972b",
          product_type: "ProductRaw",
          amount: producesCheeseFreshAmount,
        },
        {
          product: "660df7184733ba77dbf02d82",
          product_type: "ProductRaw",
          amount: producesWheyAmount,
        },
      ],
      used: [
        { product: wheyRawID, amount: wheyRawAmount },
        { product: saltID, amount: saltAmount },
      ],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Hurdu / Sveži Sir</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseWheyRaw && warehouseWheyRaw.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Surutka Poluproizvod"}
                  options={warehouseWheyRawSelect}
                  value={wheyRawID}
                  onChange={(e) => {
                    setWheyRawID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (L)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={wheyRawAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleWheyRawChange(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Surutka Poluproizvod za proizvodnju
                </FormError>
                <FormLink to={"/production/produce/cheese-fatfull-young"}>
                  Proizvedi Surutku Poluproizvod
                </FormLink>
              </>
            )}
          </FormInputHolder>

          <FormInputHolder>
            {warehouseSalt && warehouseSalt.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"So"}
                  options={warehouseSaltSelect}
                  value={saltID}
                  onChange={(e) => {
                    setSaltID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.01"
                  min={1}
                  value={saltAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleSaltChange(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje So za proizvodnju</FormError>
                <FormLink to={"/warehouse/add/material"}>Dodaj So</FormLink>
              </>
            )}
          </FormInputHolder>

          {producesCheeseFreshAmount && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesCheeseFreshAmount}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesCheeseFreshAmount(input);
                  }
                }}
              />
              <FormNotice>
                Proizvedi {producesCheeseFreshAmount}kg Svežeg Sira
              </FormNotice>
            </FormInputHolder>
          )}
          {isProduceable && producesCheeseFreshAmount && (
            <FormSubmit onClick={handleProductionSubmit}>Proizvedi</FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceCheeseFresh;
