import React, { useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import FormSelect from "../../components/form/FormSelect";
import SectionHeading from "../../components/typography/SectionHeading";

const MaterialAdd = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [materialName, setMaterialName] = useState();

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      name: materialName,
    };
    const response = await apiRequest.post("/material/add", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/material/view";
    } else {
      setFormError(response.error);
    }
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Materijal</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            icon={"bag-seedling"}
            placeholder={"Naziv"}
            onChange={(e) => {
              setMaterialName(e.target.value);
            }}
          />
          {formError && <FormError>{formError}</FormError>}
          <FormSubmit
            onClick={() => handleFormSubmit()}
            isLoading={formLoading}
          >
            Dodaj
          </FormSubmit>
        </Form>
      </SectionContent>
    </Section>
  );
};

export default MaterialAdd;
