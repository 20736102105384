import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import FormSelect from "../../components/form/FormSelect";
import SectionHeading from "../../components/typography/SectionHeading";
import usePackage from "../../hooks/package/usePackage";
import toPackageSelect from "../../functions/toPackageSelect";
import LoadingData from "../../components/data/LoadingData";

const ProductAdd = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [productName, setProductName] = useState();
  const [productPackageID, setProductPackageID] = useState();
  const [productPriceRetail, setProductPriceRetail] = useState();
  const [productPriceWholesale, setProductPriceWholesale] = useState();
  const [productPriceSuggested, setProductPriceSuggested] = useState();

  const { productPackages, productPackagesLoading } = usePackage();
  const productPackageSelect = toPackageSelect(productPackages);

  useEffect(() => {
    if (productPackages && productPackages.length > 0) {
      const initialProductMaterial = productPackages[0];
      setProductPackageID(initialProductMaterial._id);
    }
  }, [productPackages]);

  useEffect(() => {
    if (productPackageID) {
      const selectedPackage = productPackages.find(
        (productPackage) => productPackage._id === productPackageID
      );
      setProductPackageID(selectedPackage._id);
    }
  }, [productPackageID]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      name: productName,
      package: productPackageID,
      price: {
        retail: productPriceRetail,
        wholesale: productPriceWholesale,
        suggested: productPriceSuggested,
      },
    };
    const response = await apiRequest.post("/product/add", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/product/view";
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Proizvod</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          {productPackagesLoading ? (
            <LoadingData />
          ) : (
            <>
              {productPackages && productPackages.length > 0 ? (
                <>
                  <FormSelect
                    placeholder={"Ambalaža"}
                    options={productPackageSelect}
                    onChange={(e) => {
                      setProductPackageID(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"user"}
                    placeholder={"Naziv"}
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"euro-sign"}
                    placeholder={"Maloprodajna Cena (RSD)"}
                    onChange={(e) => {
                      setProductPriceRetail(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"euro-sign"}
                    placeholder={"Veleprodajna Cena (RSD)"}
                    onChange={(e) => {
                      setProductPriceWholesale(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"euro-sign"}
                    placeholder={"Predložena Cena (RSD)"}
                    onChange={(e) => {
                      setProductPriceSuggested(e.target.value);
                    }}
                  />
                  {formError && <FormError>{formError}</FormError>}
                  <FormSubmit
                    onClick={() => handleFormSubmit()}
                    isLoading={formLoading}
                  >
                    Dodaj
                  </FormSubmit>
                </>
              ) : (
                <> </>
              )}
            </>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProductAdd;
