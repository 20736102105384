import React from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import RetailPDF from "./pricelist/RetailPDF";
import WholesalePDF from "./pricelist/WholesalePDF";
import PDFLink from "./PDFLink";

const PrintPricelist = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Štampaj - Cenovnik</SectionHeading>
      </SectionHead>
      <SectionContent>
        <div className="flex items-center gap-2">
          <PDFLink
            document={<RetailPDF />}
            fileName={"Maloprodajni Cenovnik"}
            label={"Maloprodajni Cenovnik"}
          />
          <PDFLink
            document={<WholesalePDF />}
            fileName={"Veleprodajni Cenovnik"}
            label={"Veleprodajni Cenovnik"}
          />
        </div>
      </SectionContent>
    </Section>
  );
};

export default PrintPricelist;
