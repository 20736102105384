import React from "react";
import Label from "./Label";

const FormSelect = ({ placeholder, options, onChange }) => {
  return (
    <div className="w-full">
      <Label>{placeholder}</Label>
      <select
        onChange={onChange}
        className="bg-theme-color--inputBackground border border-theme-color--inputBorder flex flex-row h-12 items-center overflow-hidden outline-none p-2 rounded-lg shadow w-full xl:h-14 xl:text-lg"
      >
        {options &&
          options.map(({ placeholder, value }, key) => {
            return (
              <option key={key} value={value}>
                {placeholder}
              </option>
            );
          })}
      </select>
    </div>
  );
};

export default FormSelect;
