import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageLayout from "./app/components/layout/PageLayout";
import Home from "./app/pages/Home";

import Farmer from "./app/pages/farmer/Farmer";
import FarmerAdd from "./app/pages/farmer/FarmerAdd";
import FarmerEdit from "./app/pages/farmer/FarmerEdit";
import FarmerView from "./app/pages/farmer/FarmerView";
import FarmerSort from "./app/pages/farmer/FarmerSort";

import Driver from "./app/pages/driver/Driver";
import DriverAdd from "./app/pages/driver/DriverAdd";
import DriverEdit from "./app/pages/driver/DriverEdit";
import DriverView from "./app/pages/driver/DriverView";

import Milk from "./app/pages/milk/Milk";
import MilkAdd from "./app/pages/milk/MilkAdd";
import MilkEdit from "./app/pages/milk/MilkEdit";

import Product from "./app/pages/product/Product";
import ProductAdd from "./app/pages/product/ProductAdd";
import ProductEdit from "./app/pages/product/ProductEdit";
import ProductView from "./app/pages/product/ProductView";
import ProductSort from "./app/pages/product/ProductSort";

import Print from "./app/pages/print/Print";
import Business from "./app/pages/business/Business";
import MilkView from "./app/pages/milk/MilkView";
import Warehouse from "./app/pages/warehouse/Warehouse";
import WarehouseView from "./app/pages/warehouse/WarehouseView";
import UserLogIn from "./app/pages/user/UserLogIn";
import GuestLayout from "./app/components/layout/GuestLayout";
import useUser from "./app/hooks/useUser";
import Production from "./app/pages/production/Production";
import ProductionProduce from "./app/pages/production/ProductionProduce";
import ProduceCreamSpread from "./app/pages/production/produce/ProduceCreamSpread";

import WorkView from "./app/pages/work/WorkView";

import BusinessAdd from "./app/pages/business/BusinessAdd";
import BusinessAddIncome from "./app/pages/business/add/BusinessAddIncome";
import BusinessAddExpense from "./app/pages/business/add/BusinessAddExpense";
import BusinessEdit from "./app/pages/business/BusinessEdit";
import BusinessEditIncome from "./app/pages/business/edit/BusinessEditIncome";
import BusinessEditExpense from "./app/pages/business/edit/BusinessEditExpense";

import WarehouseAdd from "./app/pages/warehouse/WarehouseAdd";
import PrintMilk from "./app/pages/print/PrintMilk";
import PrintPricelist from "./app/pages/print/PrintPricelist";
import WarehouseAddMaterial from "./app/pages/warehouse/add/WarehouseAddMaterial";
import WarehouseAddPackage from "./app/pages/warehouse/add/WarehouseAddPackage";
import BusinessView from "./app/pages/business/BusinessView";
import ProduceCheeseSkimmedOld from "./app/pages/production/produce/ProduceCheeseSkimmedOld";
import ProduceCheeseFatfullYoung from "./app/pages/production/produce/ProduceCheeseFatfullYoung";
import ProduceCheeseFatfullOld from "./app/pages/production/produce/ProduceCheeseFatfullOld";
import ProduceButter from "./app/pages/production/produce/ProduceButter";
import ProduceMint from "./app/pages/production/produce/ProduceMint";
import ProduceCheeseFresh from "./app/pages/production/produce/ProduceCheeseFresh";
import ProduceCream from "./app/pages/production/produce/ProduceCream";
import ProduceCreamPepper from "./app/pages/production/produce/ProduceCreamPepper";
import ProduceCreamPepperCutted from "./app/pages/production/produce/ProduceCreamPepperCutted";
import ProduceCream40 from "./app/pages/production/produce/ProduceCream40";
import ProduceCream70 from "./app/pages/production/produce/ProduceCream70";
import Package from "./app/pages/package/Package";
import PackageAdd from "./app/pages/package/PackageAdd";
import PackageEdit from "./app/pages/package/PackageEdit";
import Material from "./app/pages/material/Material";
import MaterialAdd from "./app/pages/material/MaterialAdd";
import PackageView from "./app/pages/package/PackageView";
import MaterialEdit from "./app/pages/material/MaterialEdit";
import MaterialView from "./app/pages/material/MaterialView";
import ProduceCheeseSkimmedYoung from "./app/pages/production/produce/ProduceCheeseSkimmedYoung";
import WarehouseSell from "./app/pages/warehouse/WarehouseSell";
import ProduceWhey from "./app/pages/production/produce/ProduceWhey";
import PackingPack from "./app/pages/packing/PackingPack";
import Packing from "./app/pages/packing/Packing";
import PackCream40 from "./app/pages/packing/pack/PackCream40";
import PackCream70 from "./app/pages/packing/pack/PackCream70";
import PackCreamSpread from "./app/pages/packing/pack/PackCreamSpread";
import PackCheeseSkimmedYoung from "./app/pages/packing/pack/PackCheeseSkimmedYoung";
import PackCheeseSkimmedOld from "./app/pages/packing/pack/PackCheeseSkimmedOld";
import PackCheeseFatfullYoung from "./app/pages/packing/pack/PackCheeseFatfullYoung";
import PackCheeseFatfullOld from "./app/pages/packing/pack/PackCheeseFatfullOld";
import PackButter from "./app/pages/packing/pack/PackButter";
import PackMint from "./app/pages/packing/pack/PackMint";
import PackCheeseFresh from "./app/pages/packing/pack/PackCheeseFresh";
import PackWhey from "./app/pages/packing/pack/PackWhey";
import PackCream from "./app/pages/packing/pack/PackCream";
import PackCreamPepper from "./app/pages/packing/pack/PackCreamPepper";
import PackCreamPepperCutted from "./app/pages/packing/pack/PackCreamPepperCutted";
import MilkGet from "./app/pages/milk/MilkGet";

function App() {
  const user = useUser();
  return (
    <Router>
      <Routes>
        {user ? (
          <Route path="" element={<PageLayout />}>
            <Route path="/" element={<Home />} />

            {user.userRole === "admin" && (
              <>
                <Route path="/farmer/">
                  <Route path="" element={<Farmer />} />
                  <Route path="add" element={<FarmerAdd />} />
                  <Route path="edit" element={<FarmerEdit />} />
                  <Route path="view" element={<FarmerView />} />
                  <Route path="sort" element={<FarmerSort />} />
                </Route>

                <Route path="/driver/">
                  <Route path="" element={<Driver />} />
                  <Route path="add" element={<DriverAdd />} />
                  <Route path="edit" element={<DriverEdit />} />
                  <Route path="view" element={<DriverView />} />
                </Route>

                <Route path="/milk/">
                  <Route path="" element={<Milk />} />
                  <Route path="add" element={<MilkAdd />} />
                  <Route path="edit" element={<MilkEdit />} />
                  <Route path="view" element={<MilkView />} />
                </Route>

                <Route path="/product/">
                  <Route path="" element={<Product />} />
                  <Route path="add" element={<ProductAdd />} />
                  <Route path="edit" element={<ProductEdit />} />
                  <Route path="view" element={<ProductView />} />
                  <Route path="sort" element={<ProductSort />} />
                </Route>
                <Route path="/package/">
                  <Route path="" element={<Package />} />
                  <Route path="add" element={<PackageAdd />} />
                  <Route path="edit" element={<PackageEdit />} />
                  <Route path="view" element={<PackageView />} />
                </Route>

                <Route path="/material/">
                  <Route path="" element={<Material />} />
                  <Route path="add" element={<MaterialAdd />} />
                  <Route path="edit" element={<MaterialEdit />} />
                  <Route path="view" element={<MaterialView />} />
                </Route>

                <Route path="/production/">
                  <Route path="" element={<Production />} />
                  <Route path="produce/">
                    <Route path="" element={<ProductionProduce />} />
                    <Route path="cream-40" element={<ProduceCream40 />} />
                    <Route path="cream-70" element={<ProduceCream70 />} />
                    <Route
                      path="cream-spread"
                      element={<ProduceCreamSpread />}
                    />
                    <Route
                      path="cheese-skimmed-young"
                      element={<ProduceCheeseSkimmedYoung />}
                    />
                    <Route
                      path="cheese-skimmed-old"
                      element={<ProduceCheeseSkimmedOld />}
                    />
                    <Route
                      path="cheese-fatfull-young"
                      element={<ProduceCheeseFatfullYoung />}
                    />
                    <Route
                      path="cheese-fatfull-old"
                      element={<ProduceCheeseFatfullOld />}
                    />
                    <Route path="butter" element={<ProduceButter />} />
                    <Route path="mint" element={<ProduceMint />} />
                    <Route
                      path="cheese-fresh"
                      element={<ProduceCheeseFresh />}
                    />
                    <Route path="whey" element={<ProduceWhey />} />
                    <Route path="cream" element={<ProduceCream />} />
                    <Route
                      path="cream-pepper"
                      element={<ProduceCreamPepper />}
                    />
                    <Route
                      path="cream-pepper-cutted"
                      element={<ProduceCreamPepperCutted />}
                    />
                  </Route>
                </Route>

                <Route path="/packing/">
                  <Route path="" element={<Packing />} />
                  <Route path="pack/">
                    <Route path="" element={<PackingPack />} />
                    <Route path="cream-40" element={<PackCream40 />} />
                    <Route path="cream-70" element={<PackCream70 />} />
                    <Route path="cream-spread" element={<PackCreamSpread />} />
                    <Route
                      path="cheese-skimmed-young"
                      element={<PackCheeseSkimmedYoung />}
                    />
                    <Route
                      path="cheese-skimmed-old"
                      element={<PackCheeseSkimmedOld />}
                    />
                    <Route
                      path="cheese-fatfull-young"
                      element={<PackCheeseFatfullYoung />}
                    />
                    <Route
                      path="cheese-fatfull-old"
                      element={<PackCheeseFatfullOld />}
                    />
                    <Route path="butter" element={<PackButter />} />
                    <Route path="mint" element={<PackMint />} />
                    <Route path="cheese-fresh" element={<PackCheeseFresh />} />
                    <Route path="whey" element={<PackWhey />} />
                    <Route path="cream" element={<PackCream />} />
                    <Route path="cream-pepper" element={<PackCreamPepper />} />
                    <Route
                      path="cream-pepper-cutted"
                      element={<PackCreamPepperCutted />}
                    />
                  </Route>
                </Route>

                <Route path="/warehouse/">
                  <Route path="" element={<Warehouse />} />
                  <Route path="add/">
                    <Route path="" element={<WarehouseAdd />} />
                    <Route path="material" element={<WarehouseAddMaterial />} />
                    <Route path="package" element={<WarehouseAddPackage />} />
                  </Route>
                  <Route path="sell" element={<WarehouseSell />} />
                  <Route path="view" element={<WarehouseView />} />
                </Route>

                <Route path="/work/">
                  <Route path="view" element={<WorkView />} />
                </Route>

                <Route path="/business/">
                  <Route path="" element={<Business />} />
                  <Route path="add/">
                    <Route path="" element={<BusinessAdd />} />
                    <Route path="income" element={<BusinessAddIncome />} />
                    <Route path="expense" element={<BusinessAddExpense />} />
                  </Route>
                  <Route path="edit/">
                    <Route path="" element={<BusinessEdit />} />
                    <Route path="income" element={<BusinessEditIncome />} />
                    <Route path="expense" element={<BusinessEditExpense />} />
                  </Route>
                  <Route path="view" element={<BusinessView />} />
                </Route>

                <Route path="/print/">
                  <Route path="" element={<Print />} />
                  <Route path="milk/" element={<PrintMilk />} />
                  <Route path="pricelist/">
                    <Route path="" element={<PrintPricelist />} />
                  </Route>
                </Route>
              </>
            )}

            {user.userRole === "driver" && (
              <>
                <Route path="/farmer/">
                  <Route path="" element={<Farmer />} />
                  <Route path="add" element={<FarmerAdd />} />
                  <Route path="edit" element={<FarmerEdit />} />
                  <Route path="view" element={<FarmerView />} />
                  <Route path="sort" element={<FarmerSort />} />
                </Route>

                <Route path="/milk/">
                  <Route path="" element={<Milk />} />
                  <Route path="add" element={<MilkAdd />} />
                  <Route path="edit" element={<MilkEdit />} />
                  <Route path="get" element={<MilkGet />} />
                  <Route path="view" element={<MilkView />} />
                </Route>
              </>
            )}

            {user.userRole === "accountant" && (
              <>
                <Route path="/work/">
                  <Route path="view" element={<WorkView />} />
                </Route>

                <Route path="/warehouse/">
                  <Route path="view" element={<WarehouseView />} />
                </Route>
              </>
            )}
          </Route>
        ) : (
          <Route path="" element={<GuestLayout />}>
            <Route path="/" element={<UserLogIn />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
}

export default App;
