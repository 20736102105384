import React from "react";
import { Link } from "react-router-dom";
import Icon from "../icon/Icon";

const SectionNaviagtionList = ({ items }) => {
  return (
    <ul className="grid gap-4 grid-cols-2 md:grid-cols-3">
      {items.map(({ to, icon, title }, key) => {
        return (
          <li
            key={key}
            className="bg-white border border-theme-color--listItemBorder rounded-lg shadow-sm"
          >
            <Link
              to={to}
              className="aspect-[5/4] flex flex-col items-center justify-center gap-4 h-full p-4 w-full sm:p-10 lg:gap-6 xl:p-8"
            >
              <div className="text-lg xs:text-2xl sm:text-3xl">
                <Icon icon={icon} />
              </div>
              <h3 className="text-center">{title}</h3>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default SectionNaviagtionList;
