import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackCream70 = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [cream70ID, setCream70ID] = useState();
  const [cream70Amount, setCream70Amount] = useState();
  const [package10ID, setPackage10ID] = useState();
  const [package10Amount, setPackage10Amount] = useState("");
  const [package20ID, setPackage20ID] = useState();
  const [package20Amount, setPackage20Amount] = useState("");

  const {
    warehouseProducts: warehouseCream70,
    warehouseRefetch: warehouseCream70Refetch,
  } = useWarehouseProduct(
    `product=66097524ec44f58429269723&amount=unused&date_produced=${packingDate}`
  );
  const warehouseCream70Select = useProductSelect(warehouseCream70, "kg");

  const {
    warehouseProducts: warehousePackage10,
    warehouseRefetch: warehousePackage10Refetch,
  } = useWarehouseProduct(
    `product=660f3028bad3b41bfbe674fc&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage10Select = useProductSelect(warehousePackage10, "kom");

  const {
    warehouseProducts: warehousePackage20,
    warehouseRefetch: warehousePackage20Refetch,
  } = useWarehouseProduct(
    `product=660f3042bad3b41bfbe674fd&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage20Select = useProductSelect(warehousePackage20, "kom");

  useEffect(() => {
    if (warehouseCream70 && warehouseCream70.length > 0) {
      const initialWarehouseCream70 = warehouseCream70[0];
      const initialWarehouseCream70ID = initialWarehouseCream70._id;
      setCream70ID(initialWarehouseCream70ID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCream70]);

  useEffect(() => {
    if (warehousePackage10 && warehousePackage10.length > 0) {
      const initialWarehousePackage10 = warehousePackage10[0];
      const initialWarehousePackage10ID = initialWarehousePackage10._id;
      setPackage10ID(initialWarehousePackage10ID);
    }
  }, [warehousePackage10]);

  useEffect(() => {
    if (warehousePackage20 && warehousePackage20.length > 0) {
      const initialWarehousePackage20 = warehousePackage20[0];
      const initialWarehousePackage20ID = initialWarehousePackage20._id;
      setPackage20ID(initialWarehousePackage20ID);
    }
  }, [warehousePackage20]);

  useEffect(() => {
    warehouseCream70Refetch();
    warehousePackage10Refetch();
    warehousePackage20Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (package10Amount > 0 || package20Amount > 0) {
      setIsProduceable(true);
      setCream70Amount(package10Amount * 10 + package20Amount * 20);
    } else {
      setIsProduceable(false);
      setCream70Amount(0);
    }
  }, [package10Amount, package20Amount]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseCream70.find(
      (product) => product._id === cream70ID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 10 ? 0 : package10Amount * 10) -
        (packageSize === 20 ? 0 : package20Amount * 20)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage10AmountChange = (input) => {
    const selectedPackage10 = warehousePackage10.find(
      (product) => product._id === package10ID
    );

    const maxPackageAmount10 =
      selectedPackage10.amount.produced - selectedPackage10.amount.used;

    const packageAmount = maxPackageSizeAmount(10);

    if (input > maxPackageAmount10) {
      if (maxPackageAmount10 > packageAmount) {
        setPackage10Amount(packageAmount);
      } else {
        setPackage10Amount(maxPackageAmount10);
      }
    } else {
      if (input > packageAmount) {
        setPackage10Amount(packageAmount);
      } else {
        setPackage10Amount(input);
      }
    }
  };

  const handlePackage20AmountChange = (input) => {
    const selectedPackage20 = warehousePackage20.find(
      (product) => product._id === package20ID
    );

    const maxPackageAmount20 =
      selectedPackage20.amount.produced - selectedPackage20.amount.used;

    const packageAmount = maxPackageSizeAmount(20);

    if (input > maxPackageAmount20) {
      if (maxPackageAmount20 > packageAmount) {
        setPackage20Amount(packageAmount);
      } else {
        setPackage20Amount(maxPackageAmount20);
      }
    } else {
      if (input > packageAmount) {
        setPackage20Amount(packageAmount);
      } else {
        setPackage20Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 45);
    if (package10Amount > 0) {
      produced.push({
        product: "66133bd0423f2a581273ed88",
        product_type: "Product",
        amount: package10Amount,
      });
      used.push({ product: package10ID, amount: package10Amount });
    }
    if (package20Amount > 0) {
      produced.push({
        product: "66133be2423f2a581273ed89",
        product_type: "Product",
        amount: package20Amount,
      });
      used.push({ product: package20ID, amount: package20Amount });
    }
    used.push({
      product: cream70ID,
      amount: cream70Amount,
    });
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Pavlaka Poluproizvod 70% m.m</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCream70 && warehouseCream70.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Pavlaka Poluproizvod 70% m.m"}
                  options={warehouseCream70Select}
                  value={cream70ID}
                  onChange={(e) => {
                    setCream70ID(e.target.value);
                  }}
                />
                <FormInputHolder>
                  {warehousePackage10 && warehousePackage10.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 10kg"}
                        options={warehousePackage10Select}
                        value={package10ID}
                        onChange={(e) => {
                          setCream70ID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package10Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage10AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 10kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 10kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>

                <FormInputHolder>
                  {warehousePackage20 && warehousePackage20.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 20kg"}
                        options={warehousePackage20Select}
                        value={package20ID}
                        onChange={(e) => {
                          setCream70ID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package20Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage20AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 20kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 20kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Pavlaka Poluproizvod 70% m.m za Pakovanje
                </FormError>
                <FormLink to={"/production/produce/cream-70"}>
                  Proizvedi Pavlaku Poluproizvod 70% m.m
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {cream70Amount > 0 && (
            <FormNotice>
              Spakuj {cream70Amount}kg Pavlake Poluproizvoda 70% m.m
            </FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackCream70;
