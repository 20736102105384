import { useSelector } from "react-redux";

const useUser = () => {
  const user = useSelector((state) => state.userReducer.user);
  if (user) {
    const userRole = user.role;
    const userFirstName = user.first_name;
    const userLastName = user.last_name;
    return { userRole, userFirstName, userLastName };
  } else {
    return;
  }
};

export default useUser;
