import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/UserSlice";
import userAuthThunk from "./thunk/userAuthThunk";

const store = configureStore({
  reducer: {
    userReducer: userReducer,
  },
});

store.dispatch(userAuthThunk());

export default store;
