import React from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import useMaterial from "../../hooks/material/useMaterial";
import LoadingData from "../../components/data/LoadingData";
import FormError from "../../components/form/FormError";
import FormLink from "../../components/form/FormLink";
import Form from "../../components/form/Form";
import ViewItem from "../../components/list/ViewItem";
import ViewList from "../../components/list/ViewList";
import ListItemFlexIconHeading from "../../components/list/ListItemFlexIconHeading";
import ListItemIcon from "../../components/list/ListItemIcon";
import ListItemHeading from "../../components/list/ListItemHeading";

const MaterialView = () => {
  const { productMaterials, productMaterialsLoading } = useMaterial();
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pregledaj Materijale</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productMaterialsLoading ? (
          <LoadingData />
        ) : (
          <>
            {productMaterials && productMaterials.length > 0 ? (
              <ViewList>
                {productMaterials.map((productMaterial, key) => {
                  return (
                    <ViewItem key={key}>
                      <ListItemFlexIconHeading>
                        <ListItemIcon icon={"bag-seedling"} />
                        <ListItemHeading>
                          {productMaterial.name}
                        </ListItemHeading>
                      </ListItemFlexIconHeading>
                    </ViewItem>
                  );
                })}
              </ViewList>
            ) : (
              <Form>
                <FormError>Nema Materijala za Pregledavanje</FormError>
                <FormLink to={"/productMaterial/add"}>Dodaj Materijal</FormLink>
              </Form>
            )}
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default MaterialView;
