import React, { useContext } from "react";
import { HeaderContext } from "../../../context/HeaderContextProvider";

const NavMenuToggler = () => {
  const headerContext = useContext(HeaderContext);
  const isMenuOpen = headerContext.isMenuOpen;
  const setMenuOpen = headerContext.setMenuOpen;
  return (
    <button
      onClick={() => setMenuOpen(!isMenuOpen)}
      className={`border border-transparent duration-500 flex h-12 items-center justify-center p-3 rounded-lg w-12 xl:hidden ${
        isMenuOpen
          ? "bg-theme-color--headerMenuButton border-theme-color--headerMenuButtonBorder"
          : ""
      }`}
    >
      <div className="grid gap-y-1 overflow-hidden w-full">
        <div
          className={`${
            isMenuOpen ? "-rotate-45 translate-y-2" : ""
          } bg-theme-color--headerMenuButtonBar duration-500 h-1 w-full`}
        ></div>
        <div
          className={`${
            isMenuOpen ? "translate-x-full" : ""
          } bg-theme-color--headerMenuButtonBar duration-500 h-1 w-full`}
        ></div>
        <div
          className={`${
            isMenuOpen ? "rotate-45 -translate-y-2" : ""
          } bg-theme-color--headerMenuButtonBar duration-500 h-1 w-full`}
        ></div>
      </div>
    </button>
  );
};

export default NavMenuToggler;
