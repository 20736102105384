import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackCreamPepper = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [creamPepperID, setCreamPepperID] = useState();
  const [creamPepperAmount, setCreamPepperAmount] = useState();

  const [package1ID, setPackage1ID] = useState();
  const [package1Amount, setPackage1Amount] = useState("");

  const [package2ID, setPackage2ID] = useState();
  const [package2Amount, setPackage2Amount] = useState("");

  const [package3ID, setPackage3ID] = useState();
  const [package3Amount, setPackage3Amount] = useState("");

  const [package5ID, setPackage5ID] = useState();
  const [package5Amount, setPackage5Amount] = useState("");

  const {
    warehouseProducts: warehouseCreamPepper,
    warehouseRefetch: warehouseCreamPepperRefetch,
  } = useWarehouseProduct(
    `product=660978acec44f5842926972f&amount=unused&date_produced=${packingDate}`
  );
  const warehouseCreamPepperSelect = useProductSelect(
    warehouseCreamPepper,
    "kg"
  );

  const {
    warehouseProducts: warehousePackage1,
    warehouseRefetch: warehousePackage1Refetch,
  } = useWarehouseProduct(
    `product=660f2fd449a1b746df531ac3&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage1Select = useProductSelect(warehousePackage1, "kom");

  const {
    warehouseProducts: warehousePackage2,
    warehouseRefetch: warehousePackage2Refetch,
  } = useWarehouseProduct(
    `product=660f2fea49a1b746df531ac5&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage2Select = useProductSelect(warehousePackage2, "kom");

  const {
    warehouseProducts: warehousePackage3,
    warehouseRefetch: warehousePackage3Refetch,
  } = useWarehouseProduct(
    `product=660f2ff049a1b746df531ac6&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage3Select = useProductSelect(warehousePackage3, "kom");

  const {
    warehouseProducts: warehousePackage5,
    warehouseRefetch: warehousePackage5Refetch,
  } = useWarehouseProduct(
    `product=660f3021bad3b41bfbe674fb&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage5Select = useProductSelect(warehousePackage5, "kom");

  useEffect(() => {
    if (warehouseCreamPepper && warehouseCreamPepper.length > 0) {
      const initialWarehouseCreamPepper = warehouseCreamPepper[0];
      const initialWarehouseCreamPepperID = initialWarehouseCreamPepper._id;
      setCreamPepperID(initialWarehouseCreamPepperID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCreamPepper]);

  useEffect(() => {
    if (warehousePackage1 && warehousePackage1.length > 0) {
      const initialWarehousePackage1 = warehousePackage1[0];
      const initialWarehousePackage1ID = initialWarehousePackage1._id;
      setPackage1ID(initialWarehousePackage1ID);
    }
  }, [warehousePackage1]);

  useEffect(() => {
    if (warehousePackage2 && warehousePackage2.length > 0) {
      const initialWarehousePackage2 = warehousePackage2[0];
      const initialWarehousePackage2ID = initialWarehousePackage2._id;
      setPackage2ID(initialWarehousePackage2ID);
    }
  }, [warehousePackage2]);

  useEffect(() => {
    if (warehousePackage3 && warehousePackage3.length > 0) {
      const initialWarehousePackage3 = warehousePackage3[0];
      const initialWarehousePackage3ID = initialWarehousePackage3._id;
      setPackage3ID(initialWarehousePackage3ID);
    }
  }, [warehousePackage3]);

  useEffect(() => {
    if (warehousePackage5 && warehousePackage5.length > 0) {
      const initialWarehousePackage5 = warehousePackage5[0];
      const initialWarehousePackage5ID = initialWarehousePackage5._id;
      setPackage5ID(initialWarehousePackage5ID);
    }
  }, [warehousePackage5]);

  useEffect(() => {
    warehouseCreamPepperRefetch();
    warehousePackage1Refetch();
    warehousePackage2Refetch();
    warehousePackage3Refetch();
    warehousePackage5Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (
      package1Amount > 0 ||
      package2Amount > 0 ||
      package3Amount > 0 ||
      package5Amount > 0
    ) {
      setIsProduceable(true);
      setCreamPepperAmount(
        package1Amount * 1 +
          package2Amount * 2 +
          package3Amount * 3 +
          package5Amount * 5
      );
    } else {
      setIsProduceable(false);
      setCreamPepperAmount(0);
    }
  }, [package1Amount, package2Amount, package3Amount, package5Amount]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseCreamPepper.find(
      (product) => product._id === creamPepperID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 1 ? 0 : package1Amount * 1) -
        (packageSize === 2 ? 0 : package2Amount * 2) -
        (packageSize === 3 ? 0 : package3Amount * 3) -
        (packageSize === 5 ? 0 : package5Amount * 5)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage1AmountChange = (input) => {
    const selectedPackage1 = warehousePackage1.find(
      (product) => product._id === package1ID
    );
    const maxPackageAmount1 =
      selectedPackage1.amount.produced - selectedPackage1.amount.used;

    const packageAmount = maxPackageSizeAmount(1);

    if (input > maxPackageAmount1) {
      if (maxPackageAmount1 > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(maxPackageAmount1);
      }
    } else {
      if (input > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(input);
      }
    }
  };

  const handlePackage2AmountChange = (input) => {
    const selectedPackage2 = warehousePackage2.find(
      (product) => product._id === package2ID
    );
    const maxPackageAmount2 =
      selectedPackage2.amount.produced - selectedPackage2.amount.used;

    const packageAmount = maxPackageSizeAmount(2);

    if (input > maxPackageAmount2) {
      if (maxPackageAmount2 > packageAmount) {
        setPackage2Amount(packageAmount);
      } else {
        setPackage2Amount(maxPackageAmount2);
      }
    } else {
      if (input > packageAmount) {
        setPackage2Amount(packageAmount);
      } else {
        setPackage2Amount(input);
      }
    }
  };

  const handlePackage3AmountChange = (input) => {
    const selectedPackage3 = warehousePackage3.find(
      (product) => product._id === package3ID
    );
    const maxPackageAmount3 =
      selectedPackage3.amount.produced - selectedPackage3.amount.used;

    const packageAmount = maxPackageSizeAmount(3);

    if (input > maxPackageAmount3) {
      if (maxPackageAmount3 > packageAmount) {
        setPackage3Amount(packageAmount);
      } else {
        setPackage3Amount(maxPackageAmount3);
      }
    } else {
      if (input > packageAmount) {
        setPackage3Amount(packageAmount);
      } else {
        setPackage3Amount(input);
      }
    }
  };

  const handlePackage5AmountChange = (input) => {
    const selectedPackage5 = warehousePackage5.find(
      (product) => product._id === package5ID
    );
    const maxPackageAmount5 =
      selectedPackage5.amount.produced - selectedPackage5.amount.used;

    const packageAmount = maxPackageSizeAmount(5);

    if (input > maxPackageAmount5) {
      if (maxPackageAmount5 > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(maxPackageAmount5);
      }
    } else {
      if (input > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 90);
    if (package1Amount > 0) {
      produced.push({
        product: "661012f7fac9b067b8f6977e",
        product_type: "Product",
        amount: package1Amount,
      });
      used.push({ product: package1ID, amount: package1Amount });
    }
    if (package2Amount > 0) {
      produced.push({
        product: "66101315fac9b067b8f6977f",
        product_type: "Product",
        amount: package2Amount,
      });
      used.push({ product: package2ID, amount: package2Amount });
    }
    if (package3Amount > 0) {
      produced.push({
        product: "66101328fac9b067b8f69780",
        product_type: "Product",
        amount: package3Amount,
      });
      used.push({ product: package3ID, amount: package3Amount });
    }
    if (package5Amount > 0) {
      produced.push({
        product: "66101335fac9b067b8f69781",
        product_type: "Product",
        amount: package5Amount,
      });
      used.push({ product: package5ID, amount: package5Amount });
    }
    used.push({
      product: creamPepperID,
      amount: creamPepperAmount,
    });
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Paprika u Pavlaci</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCreamPepper && warehouseCreamPepper.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Paprika u Pavlaci"}
                  options={warehouseCreamPepperSelect}
                  value={creamPepperID}
                  onChange={(e) => {
                    setCreamPepperID(e.target.value);
                  }}
                />
                <FormInputHolder>
                  {warehousePackage1 && warehousePackage1.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 1kg"}
                        options={warehousePackage1Select}
                        value={package1ID}
                        onChange={(e) => {
                          setCreamPepperID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package1Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage1AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 1kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 1kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage2 && warehousePackage2.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 2kg"}
                        options={warehousePackage2Select}
                        value={package2ID}
                        onChange={(e) => {
                          setCreamPepperID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package2Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage2AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 2kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 2kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage3 && warehousePackage3.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 3kg"}
                        options={warehousePackage3Select}
                        value={package3ID}
                        onChange={(e) => {
                          setCreamPepperID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package3Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage3AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 3kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 3kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage5 && warehousePackage5.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 5kg"}
                        options={warehousePackage5Select}
                        value={package5ID}
                        onChange={(e) => {
                          setCreamPepperID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package5Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage5AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 5kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 5kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>Nedostaje Paprika u Pavlaci za Pakovanje</FormError>
                <FormLink to={"/production/produce/cream-spread"}>
                  Proizvedi Paprika u Pavlaci
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {creamPepperAmount > 0 && (
            <FormNotice>
              Spakuj {creamPepperAmount}kg Paprika u Pavlaci
            </FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackCreamPepper;
