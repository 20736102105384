import React from "react";
import { Link } from "react-router-dom";

const FormLink = ({ to, children }) => {
  return (
    <Link
      to={to}
      className="bg-theme-color--primary font-bold p-4 rounded-lg text-theme-color--primaryContrast text-center xl:p-4 xl:text-lg"
    >
      {children}
    </Link>
  );
};

export default FormLink;
