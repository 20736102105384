import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import FormSelect from "../../components/form/FormSelect";
import SectionHeading from "../../components/typography/SectionHeading";
import usePackage from "../../hooks/package/usePackage";
import toPackageSelect from "../../functions/toPackageSelect";
import LoadingData from "../../components/data/LoadingData";
import FormLink from "../../components/form/FormLink";

const PackageEdit = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [productPackageID, setProductPackageID] = useState();
  const [productPackageName, setProductPackageName] = useState();
  const [productPackageUnit, setProductPackageUnit] = useState();
  const [productPackageAmount, setProductPackageAmount] = useState();

  const { productPackages, productPackagesLoading } = usePackage();
  const productPackageSelect = toPackageSelect(productPackages);

  const productPackageUnitSelect = [
    { placeholder: "kg", value: "kg" },
    { placeholder: "gr", value: "gr" },
    { placeholder: "l", value: "l" },
  ];

  const handleProductPackageChange = (productPackage) => {
    setProductPackageID(productPackage._id);
    setProductPackageName(productPackage.name);
    setProductPackageUnit(productPackage.unit);
    setProductPackageAmount(productPackage.amount);
  };

  useEffect(() => {
    if (productPackages && productPackages.length > 0) {
      const initialProductPackage = productPackages[0];
      handleProductPackageChange(initialProductPackage);
    }
  }, [productPackages]);

  useEffect(() => {
    if (productPackageID) {
      const selectedProduct = productPackages.find(
        (productPackage) => productPackage._id === productPackageID
      );
      handleProductPackageChange(selectedProduct);
    }
  }, [productPackageID]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      id: productPackageID,
      name: productPackageName,
      unit: productPackageUnit,
      amount: productPackageAmount,
    };
    const response = await apiRequest.post("/package/edit", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/package/view";
    } else {
      setFormError(response.error);
    }
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Uredi Ambalaže</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productPackagesLoading ? (
          <LoadingData />
        ) : (
          <Form>
            {productPackages && productPackages.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Ambalaža"}
                  options={productPackageSelect}
                  onChange={(e) => {
                    setProductPackageID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"bucket"}
                  placeholder={"Naziv"}
                  value={productPackageName}
                  onChange={(e) => {
                    setProductPackageName(e.target.value);
                  }}
                />
                <FormInput
                  type="number"
                  icon={"scale-balanced"}
                  placeholder={"Količina"}
                  value={productPackageAmount}
                  onChange={(e) => {
                    setProductPackageAmount(e.target.value);
                  }}
                />
                <FormSelect
                  placeholder={"Jedinica Mere"}
                  options={productPackageUnitSelect}
                  onChange={(e) => {
                    setProductPackageUnit(e.target.value);
                  }}
                />
                {formError && <FormError>{formError}</FormError>}
                <FormSubmit
                  onClick={() => handleFormSubmit()}
                  isLoading={formLoading}
                >
                  Uredi
                </FormSubmit>
              </>
            ) : (
              <>
                <FormError>Nema Ambalaža za Uređivanje</FormError>
                <FormLink to="/package/add">Dodaj Ambalažu</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default PackageEdit;
