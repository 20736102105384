import React from "react";
import Icon from "../icon/Icon";

const LoadingData = () => {
  return (
    <div className="flex flex-col gap-4 items-center justify-center text-3xl xl:text-4xl">
      <Icon icon={"spinner"} iconAnimation={"spin-pulse"} iconStyle="solid" />
      <h2 className="font-medium text-lg xl:text-xl">Učitava se...</h2>
    </div>
  );
};

export default LoadingData;
