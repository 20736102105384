import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/apiRequest";

const userAuthThunk = createAsyncThunk("user/auth", async () => {
  let user = localStorage.getItem("user");
  if (user) {
    try {
      user = JSON.parse(user);
      const request = {
        role: user.role,
        key: user.auth.key,
        secret: user.auth.secret,
      };
      const response = await apiRequest.post("/user/auth", request);
      if (response.status_code === 200) {
        user = response.user;
        localStorage.setItem("user", JSON.stringify(user));
        return response.user;
      } else {
        localStorage.removeItem("user");
        return;
      }
    } catch (error) {
      return;
    }
  }
  return;
});

export default userAuthThunk;
