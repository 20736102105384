import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useContext } from "react";
import toSizePDF from "../../../functions/toSizePDF";
import toDateReadable from "../../../functions/toDateReadable";

import logo from "../../../assets/media/logo.png";
import PoppinsRegular from "../../../assets/fonts/PoppinsRegular-400.ttf";
import PoppinsBold from "../../../assets/fonts/PoppinsBold-700.ttf";
import { MilkPrintContext } from "../../../context/MilkPrintContextProvider";

Font.register({
  family: "Poppins",
  src: PoppinsRegular,
});

Font.register({
  family: "Poppins",
  src: PoppinsBold,
});

function calculateSum(data) {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum += data[i].amount;
  }
  return sum;
}

const EnvelopePDF = ({
  farmers,
  milks,
  milkFarmPrice,
  startDate,
  endDate,
  certificateStart,
  milkQualities,
}) => {
  let farmerNum = 0;
  return (
    <Document
      title={`Mleko Koverte - ( ${toDateReadable(
        new Date(startDate)
      )} - ${toDateReadable(new Date(endDate))} )`}
    >
      {farmers.map((farmer) => {
        const farmerID = farmer._id;
        const farmerFirstName = farmer.first_name;
        const farmerLastName = farmer.last_name;
        const farmerMilks = milks.filter(
          (milk) => milk.farmer._id === farmerID
        );
        if (
          farmerMilks &&
          farmerMilks.length > 0 &&
          calculateSum(farmerMilks) > 0
        ) {
          farmerNum += 1;
          return (
            <Page
              size={{ width: toSizePDF(230), height: toSizePDF(110) }}
              style={{
                backgroundColor: "#fff",
                fontFamily: "Poppins",
              }}
            >
              <Image
                src={logo}
                style={{
                  width: toSizePDF(90),
                  marginLeft: toSizePDF(15),
                  marginTop: toSizePDF(15),
                }}
              />
              <Text
                style={{
                  marginLeft: toSizePDF(70),
                  marginTop: toSizePDF(20),
                  fontSize: 30,
                  textAlign: "center",
                }}
              >
                {farmerNum}. {farmerFirstName} {farmerLastName}
              </Text>
            </Page>
          );
        }
      })}
    </Document>
  );
};

export default EnvelopePDF;
