import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import Form from "../../../components/form/Form";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormError from "../../../components/form/FormError";
import FormSelect from "../../../components/form/FormSelect";
import SectionHeading from "../../../components/typography/SectionHeading";
import usePackage from "../../../hooks/package/usePackage";
import toPackageSelect from "../../../functions/toPackageSelect";
import LoadingData from "../../../components/data/LoadingData";
import FormLink from "../../../components/form/FormLink";

const WarehouseAddProductPackage = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [productPackageID, setProductPackageID] = useState();
  const [productPackageDateArrived, setProductPackageDateArrived] = useState();
  const [productPackageAmount, setProductPackageAmount] = useState();

  const { productPackages, productPackagesLoading } = usePackage();
  const productPackageSelect = toPackageSelect(productPackages);

  const handleProductPackageChange = (productPackage) => {
    setProductPackageID(productPackage._id);
  };

  useEffect(() => {
    if (productPackages && productPackages.length > 0) {
      const initialProductPackage = productPackages[0];
      handleProductPackageChange(initialProductPackage);
    }
  }, [productPackages]);

  useEffect(() => {
    if (productPackageID) {
      const selectedProduct = productPackages.find(
        (productPackage) => productPackage._id === productPackageID
      );
      handleProductPackageChange(selectedProduct);
    }
  }, [productPackageID]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      product: productPackageID,
      product_type: "ProductPackage",
      amount: { produced: productPackageAmount },
      date: { produced: new Date(productPackageDateArrived) },
    };
    const response = await apiRequest.post("/warehouse/add", request);
    if (response.status_code === 200) {
      window.location.href = "/warehouse/view";
    } else {
      setFormError(response.error);
      setLoadingData(false);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Ambalažu u Magacin</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productPackagesLoading ? (
          <LoadingData />
        ) : (
          <Form>
            {productPackages && productPackages.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Ambalaža"}
                  options={productPackageSelect}
                  onChange={(e) => {
                    handleFormChange();
                    setProductPackageID(e.target.value);
                  }}
                />
                <FormInput
                  type="date"
                  icon={"calendar-day"}
                  placeholder={"Veza Prijema"}
                  onChange={(e) => {
                    handleFormChange();
                    setProductPackageDateArrived(e.target.value);
                  }}
                />
                <FormInput
                  icon={"bucket"}
                  placeholder={"Količina"}
                  onChange={(e) => {
                    handleFormChange();
                    setProductPackageAmount(e.target.value);
                  }}
                />
                {formError && <FormError>{formError}</FormError>}
                <FormSubmit
                  onClick={() => handleFormSubmit()}
                  isLoading={formLoading}
                >
                  Dodaj
                </FormSubmit>
              </>
            ) : (
              <>
                <FormError>Nema Ambalaže za Dodavanje u Magacin</FormError>
                <FormLink to={"/package/add"}>Dodaj Ambalažu</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default WarehouseAddProductPackage;
