import {
  Document,
  Font,
  Image,
  PDFViewer,
  Page,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useContext } from "react";
import toSizePDF from "../../../functions/toSizePDF";
import toDateReadable from "../../../functions/toDateReadable";

import logo from "../../../assets/media/logo.png";
import PoppinsRegular from "../../../assets/fonts/PoppinsRegular-400.ttf";
import PoppinsBold from "../../../assets/fonts/PoppinsBold-700.ttf";
import toDateFormated from "../../../functions/toDateFormated";

Font.register({
  family: "Poppins",
  src: PoppinsRegular,
});

Font.register({
  family: "Poppins",
  src: PoppinsBold,
});

function calculateSum(data) {
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum += data[i].amount;
  }
  return sum;
}

const SignaturePDF = ({
  farmers,
  milks,
  milkFarmPrice,
  milkNoFarmPrice,
  startDate,
  endDate,
}) => {
  let farmerNum = 0;
  let allPriceTotal = 0;
  let allMilkTotal = 0;

  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  const dates = [];

  for (
    let date = new Date(startDateObj);
    date <= endDateObj;
    date.setDate(date.getDate() + 1)
  ) {
    date.setHours(0, 0, 0, 0);
    dates.push(toDateFormated(new Date(date)));
  }
  return (
    <Document
      title={`Mleko Potpisi - ( ${toDateReadable(
        new Date(startDate)
      )} - ${toDateReadable(new Date(endDate))} )`}
    >
      <Page
        size="A4"
        style={{
          backgroundColor: "#fff",
          fontFamily: "Poppins",
        }}
      >
        <Image
          src={logo}
          style={{
            width: toSizePDF(120),
            marginTop: toSizePDF(60),
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <View style={{ marginTop: toSizePDF(82), textAlign: "center" }}>
          <Text>Lista potpisa isplaćenog mleka</Text>
          <Text>
            {toDateReadable(new Date(startDate))}
            {" - "}
            {toDateReadable(new Date(endDate))}
          </Text>
        </View>
      </Page>
      <Page
        size="A4"
        style={{
          backgroundColor: "#fff",
          fontFamily: "Poppins",
        }}
        wrap
      >
        <View style={{ marginTop: toSizePDF(10) }} fixed>
          <Image
            src={logo}
            style={{
              width: toSizePDF(60),
              margin: "0 auto",
            }}
          />
          <View
            style={{
              marginTop: toSizePDF(5),
              marginBottom: toSizePDF(5),
              textAlign: "center",
              fontSize: 15,
            }}
          >
            <Text>Isplaćeno mleko</Text>
            <Text>
              {toDateReadable(new Date(startDate))}
              {" - "}
              {toDateReadable(new Date(endDate))}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 13,
              fontWeight: 700,
              justifyContent: "center",
            }}
          >
            <View
              style={{
                height: toSizePDF(10),
                width: toSizePDF(10),
                border: "1px solid #000",
                backgroundColor: "#13007c",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>Br</Text>
            </View>
            <View
              style={{
                height: toSizePDF(10),
                width: toSizePDF(60),
                border: "1px solid #000",
                backgroundColor: "#13007c",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>Ime i Prezime</Text>
            </View>
            <View
              style={{
                height: toSizePDF(10),
                width: toSizePDF(30),
                border: "1px solid #000",
                backgroundColor: "#13007c",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>Količina</Text>
            </View>
            <View
              style={{
                height: toSizePDF(10),
                width: toSizePDF(40),
                border: "1px solid #000",
                backgroundColor: "#13007c",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>Iznos</Text>
            </View>
            <View
              style={{
                height: toSizePDF(10),
                width: toSizePDF(50),
                border: "1px solid #000",
                backgroundColor: "#13007c",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text>Potpis</Text>
            </View>
          </View>
        </View>
        {farmers.map((farmer) => {
          let milkTotal = 0,
            priceTotal = 0;
          const farmerID = farmer._id;
          const farmerFirstName = farmer.first_name;
          const farmerLastName = farmer.last_name;
          const farmerMilks = milks.filter(
            (milk) => milk.farmer._id === farmerID
          );
          if (
            farmerMilks &&
            farmerMilks.length > 0 &&
            calculateSum(farmerMilks) > 0
          ) {
            farmerNum += 1;
            const fill = farmerNum % 2 === 1;

            dates.map((date, index) => {
              const milkDates = farmerMilks.filter(
                (milk) => toDateFormated(new Date(milk.date)) === date
              );
              const milkDate = milkDates[0];
              const milkAmount = milkDate ? milkDate.amount : 0;
              const milkFarm = milkDate ? milkDate.farm : false;
              const milkPrice = milkFarm ? milkFarmPrice : milkNoFarmPrice;
              milkTotal += milkAmount;
              priceTotal += milkAmount * milkPrice;
              allMilkTotal += milkAmount;
              allPriceTotal += milkAmount * milkPrice;
            });

            return (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 13,
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(10),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{farmerNum}</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(60),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>
                      {farmerFirstName} {farmerLastName}
                    </Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(30),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{milkTotal} L</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(40),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{priceTotal} RSD</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(50),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  ></View>
                </View>
              </>
            );
          }
        })}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 13,
            justifyContent: "center",
            marginTop: toSizePDF(5),
          }}
        >
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(50),
            }}
          ></View>
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(70),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
            }}
          >
            <Text>Količina</Text>
          </View>
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(70),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
            }}
          >
            <Text>Iznos</Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 13,
            justifyContent: "center",
          }}
        >
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(50),
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 700,
            }}
          >
            <Text>Ukupno</Text>
          </View>
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(70),
              border: "1px solid #000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>{allMilkTotal} L</Text>
          </View>
          <View
            style={{
              height: toSizePDF(10),
              width: toSizePDF(70),
              border: "1px solid #000",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>{allPriceTotal} RSD</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default SignaturePDF;
