import React from "react";

const FormInputHolder = ({ children }) => {
  return (
    <div className="border grid items-end gap-4 p-4 rounded-xl shadow md:p-6 lg:p-8 xl:p-10">
      {children}
    </div>
  );
};

export default FormInputHolder;
