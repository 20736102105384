import { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const useBusiness = (query) => {
  const [business, setBusiness] = useState();
  const [businessLoading, setBusinessLoading] = useState(true);
  const [businessError, setBusinessError] = useState(null);

  const getBusiness = async () => {
    try {
      const response = await apiRequest.get(`/business/get?${query}`);
      if (response.status_code === 200 && response.result) {
        const business = response.result;
        setBusiness(business);
      } else {
        setBusinessError("Failed to fetch data");
      }
    } catch (error) {
      setBusinessError("Failed to fetch data");
    } finally {
      setBusinessLoading(false);
    }
  };

  useEffect(() => {
    getBusiness();
  }, []);

  return {
    business,
    businessLoading,
    businessError,
    businessRefetch: getBusiness,
  }; // Return a promise if needed
};

export default useBusiness;
