import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import useCookie from "../../../hooks/useCookie";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import toDateFormated from "../../../functions/toDateFormated";
import apiRequest from "../../../api/apiRequest";

const ProduceCheeseFatfullOld = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProduceable, setIsProduceable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");

  const [producesCheeseFatfullOld, setProducesCheeseFatfullOld] = useState();
  const {
    warehouseProducts: warehouseCheeseFatfullYoung,
    warehouseRefetch: warehouseCheeseFatfullYoungRefetch,
  } = useWarehouseProduct(
    `product=66097572ec44f58429269727&amount=unused&date_produced=${productionDate}`
  );
  const warehouseCheeseFatfullYoungSelect = useProductSelect(
    warehouseCheeseFatfullYoung,
    "kg"
  );

  const [cheeseFatfullYoungID, setCheeseFatfullYoungID] = useState();
  const [cheeseFatfullYoungAmount, setCheeseFatfullYoungAmount] = useState();

  useEffect(() => {
    if (warehouseCheeseFatfullYoung && warehouseCheeseFatfullYoung.length > 0) {
      setIsProduceable(true);
      const initialWarehouseCheeseFatfullYoung = warehouseCheeseFatfullYoung[0];
      const initialWarehouseCheeseFatfullYoungID =
        initialWarehouseCheeseFatfullYoung._id;
      setCheeseFatfullYoungID(initialWarehouseCheeseFatfullYoungID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCheeseFatfullYoung]);

  useEffect(() => {
    if (cheeseFatfullYoungID && cheeseFatfullYoungAmount) {
      setIsProduceable(true);
      const selectedCheeseFatfullYoung = warehouseCheeseFatfullYoung.find(
        (product) => product._id === cheeseFatfullYoungID
      );
      const maxCheeseFatfullYoungAmount =
        selectedCheeseFatfullYoung.amount.produced -
        selectedCheeseFatfullYoung.amount.used;

      const amount =
        cheeseFatfullYoungAmount > maxCheeseFatfullYoungAmount
          ? maxCheeseFatfullYoungAmount
          : cheeseFatfullYoungAmount;

      const producesCheeseFatfullOld = toFixedNumber(amount * 0.85);
      setCheeseFatfullYoungAmount(amount);
      setProducesCheeseFatfullOld(producesCheeseFatfullOld);
    } else {
      setIsProduceable(false);
    }
  }, [cheeseFatfullYoungID, cheeseFatfullYoungAmount]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "66097819ec44f58429269728",
          product_type: "ProductRaw",
          amount: producesCheeseFatfullOld,
        },
      ],
      used: [
        { product: cheeseFatfullYoungID, amount: cheeseFatfullYoungAmount },
      ],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Punomasni Zreli Sir</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCheeseFatfullYoung &&
            warehouseCheeseFatfullYoung.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Punomasni Mladi Sir"}
                  options={warehouseCheeseFatfullYoungSelect}
                  value={cheeseFatfullYoungID}
                  onChange={(e) => {
                    setCheeseFatfullYoungID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={cheeseFatfullYoungAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      setCheeseFatfullYoungAmount(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Punomasni Mladi Sir za proizvodnju
                </FormError>
                <FormLink to={"/production/produce/cheese-skimmed-young"}>
                  Proizvedi Punomasni Mladi Sir
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {producesCheeseFatfullOld && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesCheeseFatfullOld}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesCheeseFatfullOld(input);
                  }
                }}
              />
              <FormNotice>
                Proizvedi {producesCheeseFatfullOld}kg Punomasnog Zrelog Sira
              </FormNotice>
            </FormInputHolder>
          )}
          {isProduceable && (
            <FormSubmit onClick={handleProductionSubmit}>Proizvedi</FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceCheeseFatfullOld;
