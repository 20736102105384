import React, { useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import FormSelect from "../../components/form/FormSelect";
import SectionHeading from "../../components/typography/SectionHeading";

const PackageAdd = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const packageUnitSelect = [
    { placeholder: "kg", value: "kg" },
    { placeholder: "gr", value: "gr" },
    { placeholder: "l", value: "l" },
  ];

  const [packageName, setPackageName] = useState();
  const [packageAmount, setPackageUnitAmount] = useState();
  const [packageUnit, setPackageUnit] = useState(packageUnitSelect[0].value);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      name: packageName,
      amount: packageAmount,
      unit: packageUnit,
    };
    const response = await apiRequest.post("/package/add", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/package/view";
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Ambalažu</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            icon={"bucket"}
            placeholder={"Naziv"}
            onChange={(e) => {
              setPackageName(e.target.value);
            }}
          />
          <FormSelect
            placeholder={"Jedinica Mere"}
            options={packageUnitSelect}
            onChange={(e) => {
              setPackageUnit(e.target.value);
            }}
          />
          <FormInput
            type="number"
            icon={"scale-balanced"}
            placeholder={"Iznos Mere"}
            onChange={(e) => {
              setPackageUnitAmount(e.target.value);
            }}
          />
          {formError ? (
            <FormError>{formError}</FormError>
          ) : (
            <FormSubmit
              onClick={() => handleFormSubmit()}
              isLoading={formLoading}
            >
              Dodaj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackageAdd;
