import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const HeaderContext = createContext();

const HeaderContextProvider = ({ children }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setMenuOpen(false);
  }, [location]);

  return (
    <HeaderContext.Provider
      value={{
        isMenuOpen: isMenuOpen,
        setMenuOpen: setMenuOpen,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderContextProvider };
