import React, { useContext } from "react";
import { HeaderContext } from "../../../context/HeaderContextProvider";
import NavItem from "./NavItem";
import useUser from "../../../hooks/useUser";
import NavProfile from "./NavProfile";
import Icon from "../../icon/Icon";
import NavGroup from "./NavGroup";

const NavMenu = () => {
  const headerContext = useContext(HeaderContext);
  const isMenuOpen = headerContext.isMenuOpen;
  const { userRole, userFirstName, userLastName } = useUser();

  const handleLogOut = () => {
    localStorage.removeItem("user");
    window.location.href = "/";
  };
  return (
    <div
      className={`${
        isMenuOpen ? "max-xl:-translate-x-full" : ""
      } bg-white flex flex-col w-full max-xl:bottom-0 max-xl:fixed max-xl:transition-transform max-xl:duration-500 max-xl:left-full max-xl:top-theme-height--header xl:h-full`}
    >
      <div className="h-full overflow-y-auto w-full px-4 py-6">
        <ul className="grid gap-10">
          <NavItem to={"/"} icon={"house"} heading={"Početna"} />
          {userRole === "admin" ? (
            <>
              <NavGroup title={"Korisnici"}>
                <NavItem
                  to={"/farmer"}
                  icon={"user-cowboy"}
                  heading={"Farmeri"}
                />
                {/* <NavItem
                  to={"/driver"}
                  icon={"user-pilot-tie"}
                  heading={"Vozači"}
                /> */}
              </NavGroup>
              <NavGroup title={"Mleko i Proizvodi"}>
                <NavItem to={"/milk"} icon={"droplet"} heading={"Mleko"} />
                <NavItem to={"/product"} icon={"boxes"} heading={"Proizvodi"} />
              </NavGroup>
              <NavGroup title={"Proizvodnja i Magacin"}>
                <NavItem
                  to={"/package/view"}
                  icon={"bucket"}
                  heading={"Ambalaže"}
                />
                <NavItem
                  to={"/material/view"}
                  icon={"bag-seedling"}
                  heading={"Materijali"}
                />
                <NavItem
                  to={"/production/produce"}
                  icon={"conveyor-belt-arm"}
                  heading={"Proizvodnja"}
                />
                <NavItem
                  to={"/packing/pack"}
                  icon={"box-taped"}
                  heading={"Pakovanje"}
                />
                <NavItem
                  to={"/warehouse"}
                  icon={"warehouse-full"}
                  heading={"Magacin"}
                />
                <NavItem
                  to={"/work/view"}
                  icon={"briefcase"}
                  heading={"Pregled Rada"}
                />
              </NavGroup>
              <NavGroup title={"Poslovanje"}>
                <NavItem
                  to={"/business"}
                  icon={"user-tie"}
                  heading={"Poslovanje"}
                />
              </NavGroup>
              <NavGroup title={"Štampaj"}>
                <NavItem
                  to={"/print/milk"}
                  icon={"droplet"}
                  heading={"Mleko"}
                />
                <NavItem
                  to={"/print/pricelist"}
                  icon={"money-bill"}
                  heading={"Cenovnik"}
                />
              </NavGroup>
            </>
          ) : null}
          {userRole === "driver" ? (
            <>
              <NavGroup title={"Mleko"}>
                <NavItem
                  to={"/milk/add"}
                  icon={"plus"}
                  heading={"Dodaj Mleko"}
                />
                <NavItem
                  to={"/milk/edit"}
                  icon={"pen"}
                  heading={"Uredi Mleko"}
                />
                <NavItem
                  to={"/milk/view"}
                  icon={"magnifying-glass"}
                  heading={"Pregledaj Mleko"}
                />
              </NavGroup>
              <NavGroup title={"Farmer"}>
                <NavItem
                  to={"/farmer/add"}
                  icon={"plus"}
                  heading={"Dodaj Farmera"}
                />
                <NavItem
                  to={"/farmer/edit"}
                  icon={"pen"}
                  heading={"Uredi Farmera"}
                />
                <NavItem
                  to={"/farmer/view"}
                  icon={"magnifying-glass"}
                  heading={"Pregledaj Farmere"}
                />
                <NavItem
                  to={"/farmer/sort"}
                  icon={"bars-sort"}
                  heading={"Sortiraj Farmere"}
                />
              </NavGroup>
            </>
          ) : null}
          {userRole === "accountant" ? (
            <NavGroup title={"Magacin i Rad"}>
              <NavItem
                to={"/warehouse/view"}
                icon={"warehouse-full"}
                heading={"Magacin"}
              />
              <NavItem
                to={"/work/view"}
                icon={"briefcase"}
                heading={"Pregled Rada"}
              />
            </NavGroup>
          ) : null}
        </ul>
      </div>
      <ul className="bg-white border-t flex gap-3 p-4">
        <NavProfile
          to={"/profile"}
          icon={"user"}
          name={userFirstName + " " + userLastName}
          type={userRole}
        />
        <li>
          <button onClick={handleLogOut}>
            <div className="border border-theme-color--headerMenuItemBorder flex h-16 items-center justify-center rounded-lg text-theme-color--primary text-xl w-16">
              <Icon icon={"right-from-bracket"} iconStyle={"solid"} />
            </div>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default NavMenu;
