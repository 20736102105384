import React from "react";

const Label = ({ children }) => {
  return (
    <div className="mb-2">
      <label className="xl:text-xl">{children}</label>
    </div>
  );
};

export default Label;
