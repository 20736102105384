import { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const useProduct = (query) => {
  const [products, setProducts] = useState();
  const [productsLoading, setProductsLoading] = useState(true);
  const [productsError, setProductsError] = useState(null);

  const getProducts = async () => {
    try {
      const response = await apiRequest.get(`/product/get?${query}`);
      if (response.status_code === 200 && response.result) {
        const products = response.result;
        setProducts(products);
      } else {
        setProductsError("Failed to fetch data");
      }
    } catch (error) {
      setProductsError("Failed to fetch data");
    } finally {
      setProductsLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return {
    products,
    productsLoading,
    productsError,
    productsRefetch: getProducts,
  }; // Return a promise if needed
};

export default useProduct;
