const toFarmerSelect = (farmers) => {
  const farmerSelect =
    farmers &&
    farmers.map((farmer) => {
      return {
        placeholder: farmer.first_name + " " + farmer.last_name,
        value: farmer._id,
      };
    });
  return farmerSelect;
};

export default toFarmerSelect;
