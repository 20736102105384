import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackWhey = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [cream40ID, setWheyID] = useState();
  const [cream40Amount, setWheyAmount] = useState();
  const [package1ID, setPackage1ID] = useState();
  const [package1Amount, setPackage1Amount] = useState("");
  const [package2ID, setPackage2ID] = useState();
  const [package2Amount, setPackage2Amount] = useState("");

  const {
    warehouseProducts: warehouseWhey,
    warehouseRefetch: warehouseWheyRefetch,
  } = useWarehouseProduct(
    `product=66097884ec44f5842926972d&amount=unused&date_produced=${packingDate}`
  );
  const warehouseWheySelect = useProductSelect(warehouseWhey, "kg");

  const {
    warehouseProducts: warehousePackage1,
    warehouseRefetch: warehousePackage1Refetch,
  } = useWarehouseProduct(
    `product=6610127ee9b8f4ce359e7eb4&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage1Select = useProductSelect(warehousePackage1, "kom");

  const {
    warehouseProducts: warehousePackage2,
    warehouseRefetch: warehousePackage2Refetch,
  } = useWarehouseProduct(
    `product=6610128de9b8f4ce359e7eb5&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage2Select = useProductSelect(warehousePackage2, "kom");

  useEffect(() => {
    if (warehouseWhey && warehouseWhey.length > 0) {
      const initialWarehouseWhey = warehouseWhey[0];
      const initialWarehouseWheyID = initialWarehouseWhey._id;
      setWheyID(initialWarehouseWheyID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseWhey]);

  useEffect(() => {
    if (warehousePackage1 && warehousePackage1.length > 0) {
      const initialWarehousePackage1 = warehousePackage1[0];
      const initialWarehousePackage1ID = initialWarehousePackage1._id;
      setPackage1ID(initialWarehousePackage1ID);
    }
  }, [warehousePackage1]);

  useEffect(() => {
    if (warehousePackage2 && warehousePackage2.length > 0) {
      const initialWarehousePackage2 = warehousePackage2[0];
      const initialWarehousePackage2ID = initialWarehousePackage2._id;
      setPackage2ID(initialWarehousePackage2ID);
    }
  }, [warehousePackage2]);

  useEffect(() => {
    warehouseWheyRefetch();
    warehousePackage1Refetch();
    warehousePackage2Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (package1Amount > 0 || package2Amount > 0) {
      setIsProduceable(true);
      setWheyAmount(package1Amount * 1 + package2Amount * 2);
    } else {
      setIsProduceable(false);
      setWheyAmount(0);
    }
  }, [package1Amount, package2Amount]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseWhey.find(
      (product) => product._id === cream40ID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 1 ? 0 : package1Amount * 1) -
        (packageSize === 2 ? 0 : package2Amount * 2)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage1AmountChange = (input) => {
    const selectedPackage1 = warehousePackage1.find(
      (product) => product._id === package1ID
    );

    const maxPackageAmount1 =
      selectedPackage1.amount.produced - selectedPackage1.amount.used;

    const packageAmount = maxPackageSizeAmount(1);

    if (input > maxPackageAmount1) {
      if (maxPackageAmount1 > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(maxPackageAmount1);
      }
    } else {
      if (input > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(input);
      }
    }
  };

  const handlePackage2AmountChange = (input) => {
    const selectedPackage2 = warehousePackage2.find(
      (product) => product._id === package2ID
    );

    const maxPackageAmount2 =
      selectedPackage2.amount.produced - selectedPackage2.amount.used;

    const packageAmount = maxPackageSizeAmount(2);

    if (input > maxPackageAmount2) {
      if (maxPackageAmount2 > packageAmount) {
        setPackage2Amount(packageAmount);
      } else {
        setPackage2Amount(maxPackageAmount2);
      }
    } else {
      if (input > packageAmount) {
        setPackage2Amount(packageAmount);
      } else {
        setPackage2Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 20);
    if (package1Amount > 0) {
      produced.push({
        product: "66101268fac9b067b8f6977b",
        product_type: "Product",
        amount: package1Amount,
      });
      used.push({ product: package1ID, amount: package1Amount });
    }
    if (package2Amount > 0) {
      produced.push({
        product: "661012aefac9b067b8f6977c",
        product_type: "Product",
        amount: package2Amount,
      });
      used.push({ product: package2ID, amount: package2Amount });
    }
    used.push({
      product: cream40ID,
      amount: cream40Amount,
    });
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Surutka</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseWhey && warehouseWhey.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Surutka"}
                  options={warehouseWheySelect}
                  value={cream40ID}
                  onChange={(e) => {
                    setWheyID(e.target.value);
                  }}
                />
                <FormInputHolder>
                  {warehousePackage1 && warehousePackage1.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 1kg"}
                        options={warehousePackage1Select}
                        value={package1ID}
                        onChange={(e) => {
                          setWheyID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package1Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage1AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 1kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 1kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>

                <FormInputHolder>
                  {warehousePackage2 && warehousePackage2.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 2kg"}
                        options={warehousePackage2Select}
                        value={package2ID}
                        onChange={(e) => {
                          setWheyID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package2Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage2AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 2kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 2kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>Nedostaje Surutka za Pakovanje</FormError>
                <FormLink to={"/production/produce/cream-40"}>
                  Proizvedi Pavlaku Poluproizvod 40% m.m
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {cream40Amount > 0 && (
            <FormNotice>Spakuj {cream40Amount}l Surutke</FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackWhey;
