import React from "react";
import Section from "../../components/section/Section";
import SectionNaviagtionList from "../../components/section/SectionNaviagtionList";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";

const WarehouseAdd = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Magacin - Dodaj</SectionHeading>
      </SectionHead>
      <SectionContent>
        <SectionNaviagtionList
          items={[
            { to: "package", icon: "bucket", title: "Ambalažu" },
            {
              to: "material",
              icon: "bag-seedling",
              title: "Materijal",
            },
          ]}
        />
      </SectionContent>
    </Section>
  );
};

export default WarehouseAdd;
