import React from "react";
import EnvelopePDF from "./EnvelopePDF";
import ReceiptPDF from "./ReceiptPDF";
import SignaturePDF from "./SignaturePDF";
import toDateReadable from "../../../functions/toDateReadable";

import PDFLink from "../PDFLink";

const PrintRES = ({
  farmers,
  milks,
  milkFarmPrice,
  milkNoFarmPrice,
  startDate,
  endDate,
  certificateStart,
  milkQualities,
}) => {
  const startDateReadable = toDateReadable(new Date(startDate));
  const endDateReadable = toDateReadable(new Date(endDate));

  const receiptDocument = (
    <ReceiptPDF
      farmers={farmers}
      milks={milks}
      milkFarmPrice={milkFarmPrice}
      milkNoFarmPrice={milkNoFarmPrice}
      startDate={startDate}
      endDate={endDate}
    />
  );

  const envelopeDocument = (
    <EnvelopePDF
      farmers={farmers}
      milks={milks}
      startDate={startDate}
      endDate={endDate}
      certificateStart={certificateStart}
      milkQualities={milkQualities}
    />
  );

  const signatureDocument = (
    <SignaturePDF
      farmers={farmers}
      milks={milks}
      milkFarmPrice={milkFarmPrice}
      milkNoFarmPrice={milkNoFarmPrice}
      startDate={startDate}
      endDate={endDate}
    />
  );

  return (
    <div className="flex flex-wrap items-center gap-2 mb-4">
      <PDFLink
        document={receiptDocument}
        fileName={`Mleko - Obračuni (${startDateReadable} - ${endDateReadable}).pdf`}
        label="Obračuni"
      />
      <PDFLink
        document={envelopeDocument}
        fileName={`Mleko - Koverte (${startDateReadable} - ${endDateReadable}).pdf`}
        label="Koverte"
      />
      <PDFLink
        document={signatureDocument}
        fileName={`Mleko - Potpisi (${startDateReadable} - ${endDateReadable}).pdf`}
        label="Potpisi"
      />
    </div>
  );
};

export default PrintRES;
