import React from "react";

const SectionHead = ({ children }) => {
  return (
    <div className="bg-theme-color--primary border-b flex items-center justify-center p-4 text-theme-color--primaryContrast sm:p-6">
      {children}
    </div>
  );
};

export default SectionHead;
