import React from "react";
import Section from "../../components/section/Section";
import SectionNaviagtionList from "../../components/section/SectionNaviagtionList";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";

const Production = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvodnja</SectionHeading>
      </SectionHead>
      <SectionContent>
        <SectionNaviagtionList
          items={[
            {
              to: "produce",
              icon: "plus",
              title: "Proizvedi",
            },
            {
              to: "view",
              icon: "magnifying-glass",
              title: "Pregledaj",
            },
          ]}
        />
      </SectionContent>
    </Section>
  );
};

export default Production;
