import React, { useState } from "react";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import FormSubmit from "../../components/form/FormSubmit";
import { useDispatch } from "react-redux";

const UserLogIn = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();
  const [userUsername, setUserUsername] = useState();
  const [userPassword, setUserPassword] = useState();
  const dispatch = useDispatch();

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      username: userUsername,
      password: userPassword,
    };
    try {
      const response = await apiRequest.post("/user/login", request);
      setLoadingData(false);
      if (response.status_code === 200) {
        localStorage.setItem("user", JSON.stringify(response.user));
        window.location.reload();
      } else {
        setFormError(response.error);
      }
    } catch (error) {
      setFormError(error);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <section className="bg-white border max-w-screen-xs overflow-hidden rounded-xl shadow-sm w-full">
      <div className="flex flex-col gap-y-10 items-center justify-center px-6 py-8 sm:gap-y-16 sm:px-8 sm:py-10">
        <h1 className="font-medium text-3xl">Prijava</h1>
        <Form>
          <FormInput
            icon={"at"}
            placeholder={"Korisničko Ime"}
            onChange={(e) => {
              handleFormChange();
              setUserUsername(e.target.value);
            }}
          />
          <FormInput
            icon={"key"}
            type="password"
            placeholder={"Lozinka"}
            onChange={(e) => {
              handleFormChange();
              setUserPassword(e.target.value);
            }}
          />
          {formError ? (
            <FormError>{formError}</FormError>
          ) : (
            <FormSubmit
              onClick={() => handleFormSubmit()}
              isLoading={formLoading}
            >
              Prijavite se
            </FormSubmit>
          )}
        </Form>
      </div>
    </section>
  );
};

export default UserLogIn;
