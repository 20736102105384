import axios from "axios";

const apiRequest = axios.create({
  baseURL: "https://api.ecomilkpester.com",
});

apiRequest.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject({ message: error.message });
  }
);

export default apiRequest;
